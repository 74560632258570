import { MenuProps, ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _get from 'lodash/get';

import { Resources } from 'types';

import CustomMenuItem from './CustomMenuItem';
import MenuSection from './MenuSection';

// Used styles and constants are cloned from `Menu` component of `react-admin`
const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: _get(theme, 'menu.width', '100%'),
    },
    closed: {
      width: _get(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
    dashboardItem: {
      color: theme.palette.text.secondary,
    },
  }),
  { name: 'RaMenu' },
);

const userResources = [Resources.HC_PROVIDERS, Resources.CLINICIANS, Resources.PATIENTS, Resources.THREAD_MODERATORS];
const statisticResources = [Resources.MARKETING_WEBSITE_USERS, Resources.ONBOARDING_SURVEYS];

const SectionedMenu = (props: MenuProps) => {
  // Take out `hasDashboard` prop because it not used in this component
  // but raises a warning when passed to a `div` element
  const { hasDashboard, ...restProps } = props;
  const classes = useStyles(props);
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  return (
    <div
      className={classnames(classes.main, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
      {...restProps}
    >
      <CustomMenuItem key="dashboard" label="Dashboard" className={classes.dashboardItem} />
      <MenuSection sectionName="Users" resources={userResources} />
      <MenuSection sectionName="Statistic" resources={statisticResources} />
    </div>
  );
};

export default SectionedMenu;
