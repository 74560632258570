import { gql } from '@apollo/client';

const hcProviderPOCFields = `
  id
  isPrimary
  firstName
  lastName
  role
  email
  phone
  `;

export const CREATE = gql`
  mutation createHCProvider($hcProvider: HCProviderCreate) {
    data: createHCProvider(hcProvider: $hcProvider) {
      id
    }
  }
`;

export const GET_ONE = gql`
  query hcProvider($id: Int!) {
    data: hcProvider(id: $id) {
      id
      name
      registrationHash
      isDefault
      primaryPOC {
        ${hcProviderPOCFields}
      }
      secondaryPOC {
        ${hcProviderPOCFields}
      }
      clinicians {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_LIST = gql`
  query hcProviders($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: hcProviders(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
      name
      registrationHash
    }
    total: hcProviders(where: $where) {
      id
    }
  }
`;

export const GET_MANY = GET_LIST;

export const UPDATE = gql`
  mutation updateHCProvider($hcProvider: HCProviderUpdate) {
    data: updateHCProvider(hcProvider: $hcProvider) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation destroyHCProvider($hcProvider: HCProviderDestroy!) {
    data: destroyHCProvider(hcProvider: $hcProvider) {
      id
    }
  }
`;

export const DELETE_MANY = gql`
  mutation bulkDestroyHCProviders($hcProviders: HCProvidersBulkDestroy!) {
    data: bulkDestroyHCProviders(hcProviders: $hcProviders)
  }
`;
