import { FC, useState } from 'react';

import { Tab as MUITab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

import { CheckInsTable, CoinTransactionsTable, MomentsTable, ReviewsTable } from '../components';
import { patientFields } from '../types';

interface Props {
  patientId: number;
}

export const UserActivitiesTables: FC<Props> = ({ patientId }) => {
  const [tab, setTab] = useState(0);
  const changeTab = (event, value) => setTab(value);

  return (
    <div style={{ minHeight: 400 }}>
      <AppBar position="static">
        <Tabs value={tab} onChange={changeTab}>
          <MUITab label={patientFields.moments.admin} />
          <MUITab label={patientFields.reviews.admin} />
          <MUITab label={patientFields.checkIns.admin} />
          <MUITab label={patientFields.coins.admin} />
        </Tabs>
      </AppBar>
      {tab === 0 && <MomentsTable patientId={patientId} />}
      {tab === 1 && <ReviewsTable patientId={patientId} />}
      {tab === 2 && <CheckInsTable patientId={patientId} />}
      {tab === 3 && <CoinTransactionsTable patientId={patientId} />}
    </div>
  );
};
