import { clinicianFieldNames } from 'resources/clinician/types';
import { hcProviderFieldNames } from 'resources/hcProvider/types';
import { patientFields } from 'resources/patient/types';
import { Resources } from 'types';

export const deleteOneDialogOptions = {
  [Resources.HC_PROVIDERS]: {
    title: 'Delete Organization',
    resourceLabel: 'organization',
    recordNamePath: hcProviderFieldNames.name.db,
  },
  [Resources.PATIENTS]: {
    title: 'Delete Patient',
    resourceLabel: 'patient',
    recordNamePath: patientFields.profile.name.db,
  },
  [Resources.CLINICIANS]: {
    title: 'Delete Clinician',
    resourceLabel: 'clinician',
    recordNamePath: clinicianFieldNames.fullName.db,
  },
};

export const deleteManyDialogOptions = {
  [Resources.HC_PROVIDERS]: {
    title: 'Delete Organizations',
    resourceLabels: {
      singular: 'organization',
      plural: 'organizations',
    },
  },
  [Resources.CLINICIANS]: {
    title: 'Delete Clinicians',
    resourceLabels: {
      singular: 'clinician',
      plural: 'clinicians',
    },
  },
  [Resources.PATIENTS]: {
    title: 'Delete Patients',
    resourceLabels: {
      singular: 'patient',
      plural: 'patients',
    },
  },
};
