import { Context, useContext, useEffect, useState } from 'react';
import { Button, Record, TopToolbar, useRedirect } from 'react-admin';

import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import { RECORDS_PER_PAGE } from 'constants/common';
import { ContextValues } from 'context';
import { client } from 'data/provider';
import { ResourceGetLists } from 'types';
import { buildOrderFromSort } from 'utils';

interface StyleProps {
  previousDisabled: boolean;
  nextDisabled: boolean;
}

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: '15px',
    textTransform: 'none',
    '& span span': {
      position: 'relative',
      padding: 0,
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'inline-block',
        width: '100%',
        height: '2px',
        opacity: 0.6,
      },
    },
  },
  backButton: {
    marginRight: '12px',
    '& span span:last-child': {
      color: ({ previousDisabled }: StyleProps) =>
        previousDisabled ? theme.palette.grey[500] : theme.palette.primary.main,
      '&::after': {
        backgroundColor: ({ previousDisabled }: StyleProps) =>
          previousDisabled ? theme.palette.grey[500] : theme.palette.primary.main,
      },
    },
  },
  nextButton: {
    '& span span:first-child': {
      color: ({ nextDisabled }: StyleProps) => (nextDisabled ? theme.palette.grey[500] : theme.palette.primary.main),
      '&::after': {
        backgroundColor: ({ nextDisabled }: StyleProps) =>
          nextDisabled ? theme.palette.grey[500] : theme.palette.primary.main,
      },
    },
  },
  arrow: {
    fontSize: '18px',
  },
}));

interface Props {
  context: Context<ContextValues>;
  record: Record | undefined;
  resource: string;
}

export const NavigationToolbar = ({ context, resource, record }: Props) => {
  const redirect = useRedirect();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { setCurrentPage, currentSort } = useContext(context);

  const { data } = useQuery(ResourceGetLists[resource], {
    client,
    variables: {
      order: buildOrderFromSort(currentSort),
    },
  });

  useEffect(() => {
    if (data && data.list && record) {
      const index = data.list.findIndex(({ id }) => id === Number(record.id));
      setCurrentIndex(index);
      setCurrentPage(Math.ceil((index + 1) / RECORDS_PER_PAGE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, record]);

  const nextDisabled = currentIndex >= data?.list.length - 1;
  const previousDisabled = currentIndex <= 0;

  const styles = useStyles({ previousDisabled, nextDisabled });

  const nextHandler = () => {
    if (data && data.list && !nextDisabled) {
      redirect('show', `/${resource}`, data.list[currentIndex + 1].id);
    }
  };

  const backHandler = () => {
    if (data && data.list && !previousDisabled) {
      redirect('show', `/${resource}`, data.list[currentIndex - 1].id);
    }
  };

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={backHandler}
        key={`${previousDisabled}prev`}
        disabled={previousDisabled}
        startIcon={<ArrowBackIosIcon className={styles.arrow} />}
        label="Previous"
        className={`${styles.button} ${styles.backButton}`}
      />
      <Button
        color="primary"
        onClick={nextHandler}
        key={`${nextDisabled}next`}
        disabled={nextDisabled}
        endIcon={<ArrowForwardIos className={styles.arrow} />}
        label="Next"
        className={`${styles.button} ${styles.nextButton}`}
      />
    </TopToolbar>
  );
};
