import { FC } from 'react';
import { Confirm, MutationMode, Record, useNotify, useUpdate } from 'react-admin';

import { Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Close } from '@material-ui/icons';

import { useError } from 'hooks/useError';
import dialogTheme from 'themes/dialogTheme';

interface UpdateActionDialogProps {
  resource?: string;
  record?: Record;
  openDialog: boolean;
  recordFields: {
    [key: string]: any;
  };
  onClose: () => void;
  mutationMode?: MutationMode;
  confirmBtnName: string;
  title: string;
  content: string;
  notifySuccess: string;
}

const useStyles = makeStyles(theme => ({
  dialogConfirmBtn: {
    color: theme.palette.common.white,
    backgroundColor: deepPurple.A400,
    border: `1px solid`,
    borderColor: deepPurple.A400,
    marginLeft: 20,

    '&:hover': {
      backgroundColor: fade(deepPurple.A400, 0.7),
      borderColor: fade(deepPurple.A400, 0),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const UpdateActionDialog: FC<UpdateActionDialogProps> = ({
  resource = '',
  record,
  openDialog,
  recordFields,
  onClose,
  mutationMode,
  confirmBtnName,
  title,
  content,
  notifySuccess,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const errorNotify = useError();

  const [update, { loading }] = useUpdate(
    resource,
    record?.id,
    { id: record?.id, ...recordFields },
    {},
    {
      onSuccess: () => notify(notifySuccess, 'info', undefined, mutationMode === 'undoable'),
      onFailure: e => errorNotify(e.message, 'warning'),
      mutationMode,
    },
  );

  const confirmHandler = () => {
    update();
    onClose();
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Confirm
        confirm={confirmBtnName}
        title={title}
        content={
          <Typography>
            {content}
            <Close onClick={onClose} />
          </Typography>
        }
        isOpen={openDialog}
        loading={loading}
        onConfirm={confirmHandler}
        onClose={onClose}
        classes={{ confirmPrimary: classes.dialogConfirmBtn }}
      />
    </ThemeProvider>
  );
};

export default UpdateActionDialog;
