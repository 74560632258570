import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TransformData,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import CreateInviteToolbar from 'components/toolbars/CreateInviteToolbar';
import { Resources } from 'types';
import { Entities } from 'types/entities';

import { clinicianFieldNames } from './types';

interface CreateClinicianData {
  id: number;
  message: string;
}

export const ClinicianCreate = props => {
  const saveTransformHandler: TransformData = data => ({
    profile: {
      name: `${data.firstName} ${data.lastName}`,
      targetType: Entities.Clinicians,
    },
    providerId: data.deliveryAddress,
    hcProviderId: data.providerId,
  });

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = ({ data }: { data: CreateClinicianData }) => {
    notify(data.message);
    redirect(`/${Resources.CLINICIANS}`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CreateInviteToolbar transformOnSave={saveTransformHandler} />}>
        <TextInput
          label={clinicianFieldNames.firstName.admin}
          source={clinicianFieldNames.firstName.db}
          validate={required()}
        />
        <TextInput
          label={clinicianFieldNames.lastName.admin}
          source={clinicianFieldNames.lastName.db}
          validate={required()}
        />
        <TextInput
          label={clinicianFieldNames.email.admin}
          source={clinicianFieldNames.email.db}
          validate={required()}
        />
        <ReferenceInput
          label={clinicianFieldNames.org.admin}
          source={clinicianFieldNames.org.db}
          reference={Resources.HC_PROVIDERS}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
