import { gql } from '@apollo/client';

export const CHECKIN_SUBMISSIONS = gql`
  query checkInAnswersByDate($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: checkInAnswersByDate(order: $order, limit: $limit, offset: $offset, where: $where) {
      id: date
      date
      checkInSubmissions {
        answer
        checkInId
      }
    }
  }
`;

export const CHECKIN_SUBMISSIONS_COUNT = gql`
  query checkInAnswersByDate($where: JSON) {
    total: checkInSubmissionsCount(where: $where)
  }
`;
