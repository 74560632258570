import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { AppProvider } from 'context/appContext';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_ID)}>
      <AppProvider>
        <App />
      </AppProvider>
    </GoogleOAuthProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
