import { deepPurple, grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default createMuiTheme({
  props: {
    MuiButton: {
      disableFocusRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: deepPurple.A400,
        border: `1px solid ${deepPurple[100]}`,
        borderRadius: 4,
      },
      label: {
        '& > svg': {
          display: 'none',
        },
      },
    },
    MuiDialog: {
      paper: {
        alignItems: 'center',
        padding: '10px 50px 20px 50px',
      },
    },
    MuiDialogContent: {
      root: {
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        color: fade(grey[900], 0.87),
        position: 'relative',
        overflowY: 'visible',

        '& strong': {
          lineHeight: '3rem',
        },
        '& svg': {
          position: 'absolute',
          top: -55,
          left: -30,
          cursor: 'pointer',
          color: fade(grey[900], 0.87),
        },
      },
    },
  },
});
