import { ListButton, SaveButton, Toolbar, ToolbarProps, TransformData } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  cancel: {
    marginLeft: 'auto',
  },
});

export interface BaseCreateToolbarProps extends ToolbarProps {
  transformOnSave?: TransformData;
}

const CreateInviteToolbar = ({ children, transformOnSave, ...props }: BaseCreateToolbarProps) => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton
        icon={<SendIcon />}
        key={`${props.pristine}saveAndSendInvite`}
        disabled={props.pristine}
        label="SAVE AND SEND INVITE"
        submitOnEnter={true}
        transform={transformOnSave}
      />
      {children}
      <ListButton icon={<ClearIcon />} label="CANCEL" className={classes.cancel} />
    </Toolbar>
  );
};

export default CreateInviteToolbar;
