import { FC, useContext } from 'react';
import { DatagridRow, DatagridRowProps } from 'react-admin';

import { ResourcesContext } from 'types';

const SelectableDatagridRow: FC<DatagridRowProps> = props => {
  const { selected, onToggleItem, resource } = props;
  const { excluded, setExcluded, isSelectedAll } = useContext(ResourcesContext[resource || '']);

  const selectableToggle = (id, event) => {
    if (onToggleItem) {
      onToggleItem(id, event);
    }
    if (isSelectedAll && id && excluded) {
      selected ? setExcluded([...excluded, id]) : setExcluded(excluded.filter(value => value !== id));
    }
  };

  return <DatagridRow {...props} onToggleItem={selectableToggle} />;
};

export default SelectableDatagridRow;
