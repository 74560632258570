export const threadModeratorInviteFields = {
  id: { db: 'id', admin: 'ID' },
  email: { db: 'email', admin: 'Email' },
  name: { db: 'name', admin: 'Name' },
  status: { db: 'status', admin: 'Status' },
  expiredAt: { db: 'expireAt', admin: 'Expire At' },
  updatedAt: { db: 'updatedAt', admin: 'Updated' },
};

export enum ThreadInviteStatus {
  PENDING = 'pending',
  EXPIRED = 'expired',
}
