import { CompositionEvent, FormEvent } from 'react';
import { required, TextInput, TextInputProps } from 'react-admin';

import { isMobilePhone } from 'utils/validation';

const phoneCharsRegExp = /[^\d\s()+-]/;

/**
 * Callback that handles `beforeinput` event that occurs in `PhoneNumberInput` component
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/beforeinput_event
 * Restricts character input,
 * preventing component changes if user input contains characters that are not used in phone numbers:
 * digits, parentheses `(`,`)`, plus sign `+`, delimiters `-`, spaces.
 *
 * @param event object that contains `beforeinput` event data.
 * Note that the type of this parameter determined by `SimpleForm` component of the `react-admin`.
 * Because of this, under the hood we should convert this parameter to appropriate event type in order to get required properties
 * @returns void
 */
const onBeforeInput = (event: FormEvent<HTMLDivElement>) => {
  const inputValue = (event as unknown as CompositionEvent).data;
  if (phoneCharsRegExp.test(inputValue)) {
    event.preventDefault();
  }
};

const PhoneNumberInput = ({ isRequired = false, ...props }: TextInputProps) => {
  const validators = isRequired ? [required(), isMobilePhone(isRequired)] : isMobilePhone(isRequired);
  return <TextInput {...props} validate={validators} onBeforeInput={onBeforeInput} />;
};

export default PhoneNumberInput;
