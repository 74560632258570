import { gql } from '@apollo/client';

export const GET_CLINICIAN_LIST = gql`
  query clinicians {
    list: clinicians {
      id
    }
  }
`;

export const GET_HC_PROVIDER_LIST = gql`
  query hcProviders($limit: Int, $offset: Int, $order: String, $where: JSON) {
    list: hcProviders(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
    }
  }
`;

export const GET_MARKETING_USER_LIST = gql`
  query allWebUsers {
    list: allUsers {
      id
      name
      email
      sendNews
      source
    }
    total: allUsers {
      id
    }
  }
`;

export const GET_MOMENT_SUBMISSION_LIST = gql`
  query momentSubmissions($limit: Int, $offset: Int, $order: String) {
    list: momentSubmissions(order: $order, limit: $limit, offset: $offset) {
      id: uuid
    }
  }
`;

export const GET_PACKAGE_SEQUENCE_LIST = gql`
  query packageSequences($order: String, $offset: Int, $limit: Int, $where: JSON) {
    list: packageSequences(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
    }
  }
`;

export const GET_PATIENT_LIST = gql`
  query patients($limit: Int, $offset: Int, $order: String, $where: JSON) {
    list: patients(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
    }
  }
`;

export const GET_LOTIC_USER_LIST = gql`
  query loticUsers($order: String, $offset: Int, $limit: Int, $where: JSON) {
    list: loticUsers(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
    }
  }
`;

export const GET_ONBOARDING_SURVEY_LIST = gql`
  query onboardingSurveySubmissions($order: String, $offset: Int, $limit: Int, $where: JSON) {
    list: onboardingSurveySubmissions(order: $order, offset: $offset, limit: $limit, where: $where) {
      answerDate
    }
  }
`;
