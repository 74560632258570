import {
  Create,
  required,
  SimpleForm,
  TextInput,
  useCreateController,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PlainTextField from 'components/fields/PainTextField';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import BaseCreateToolbar from 'components/toolbars/BaseCreateToolbar';
import { HCPROVIDER_NAME_MAX_LENGTH } from 'constants/inputValidation';
import { useError } from 'hooks/useError';
import { isEmail, maxLength } from 'utils/validation';

import { hcProviderFieldNames } from './types';

const useStyles = makeStyles({
  subHeader: {
    borderBottom: '2px solid #9eadba',
    marginTop: '2%',
  },
  subGroup: {
    marginTop: '2%',
    marginBottom: '1%',
  },
});

export const HCProviderCreate = props => {
  const notify = useNotify();
  const errorNotify = useError();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { resource } = useCreateController(props);
  const classes = useStyles();

  /**
   * Callback that handles successful HCProvider create
   * @param response response data from the server
   * @returns void
   */
  const successHandler = ({ data }) => {
    notify('A new record was created with id ' + data.id);
    redirect(`/${resource}`);
    refresh();
  };

  /**
   * Callback that handles failed HCProvider create
   * @param error contains error message that was responded from the server
   * @returns void
   */
  const failureHandler = error => errorNotify(error.message, 'warning');

  return (
    <Create {...props} onSuccess={successHandler} onFailure={failureHandler}>
      <SimpleForm toolbar={<BaseCreateToolbar />}>
        <TextInput
          label={hcProviderFieldNames.name.admin}
          source={hcProviderFieldNames.name.db}
          validate={[required(), maxLength(HCPROVIDER_NAME_MAX_LENGTH)]}
        />
        <PlainTextField classes={{ root: classes.subHeader }} variant="h5">
          Points of Contact
        </PlainTextField>
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Primary
        </PlainTextField>
        <TextInput
          label={hcProviderFieldNames.primaryPOC.firstName.admin}
          source={hcProviderFieldNames.primaryPOC.firstName.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.lastName.admin}
          source={hcProviderFieldNames.primaryPOC.lastName.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.role.admin}
          source={hcProviderFieldNames.primaryPOC.role.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.email.admin}
          source={hcProviderFieldNames.primaryPOC.email.db}
          validate={[required(), isEmail(true)]}
        />
        <PhoneNumberInput
          label={hcProviderFieldNames.primaryPOC.phone.admin}
          source={hcProviderFieldNames.primaryPOC.phone.db}
          isRequired
        />
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Secondary
        </PlainTextField>
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.firstName.admin}
          source={hcProviderFieldNames.secondaryPOC.firstName.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.lastName.admin}
          source={hcProviderFieldNames.secondaryPOC.lastName.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.role.admin}
          source={hcProviderFieldNames.secondaryPOC.role.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.email.admin}
          source={hcProviderFieldNames.secondaryPOC.email.db}
          validate={isEmail(false)}
        />
        <PhoneNumberInput
          label={hcProviderFieldNames.secondaryPOC.phone.admin}
          source={hcProviderFieldNames.secondaryPOC.phone.db}
        />
      </SimpleForm>
    </Create>
  );
};
