import { downloadCSV, FunctionField, TextField, TextInput, useListController } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

import { DeleteManyDialog } from 'components/dialogs/deleteDialogs';
import { Header } from 'components/Header';
import { SelectableDatagrid, SelectableList } from 'components/SelectableList';
import { deleteManyDialogOptions } from 'constants/dialogOptions';
import { ClinicianContext } from 'context/clinician';
import { useListOptions } from 'hooks/useListOptions';
import { ResourceLabels, Resources } from 'types';

import { Clinician, clinicianFieldNames } from './types';

// const AvatarField = (props) => {
//   const { source, className, size, ...rest } = props;
//   const record = useRecordContext();
//   const src = get(record, source);
//   if (!src) {
//     return null;
//   }
//   return (
//     <div className={className} {...sanitizeFieldRestProps(rest)}>
//       <img
//         style={{
//           objectFit: "cover",
//           borderRadius: "50%",
//           width: size || 150,
//           height: size || 150,
//         }}
//         src={src}
//       />
//     </div>
//   );
// };
const useStyles = makeStyles({
  overflowColumn: {
    '& .RaDatagrid-rowCell': { overflowWrap: 'anywhere' },
  },
});

const exporter = (clinicians: Clinician[]) => {
  const cliniciansForExport = clinicians.map(({ id, providerId, HCProviders, profile }: Clinician) => ({
    [clinicianFieldNames.id.admin]: id,
    [clinicianFieldNames.fullName.admin]: profile.name,
    [clinicianFieldNames.email.admin]: providerId,
    [clinicianFieldNames.org.admin]: HCProviders[0].name,
  }));
  jsonExport(
    cliniciansForExport,
    {
      headers: Object.keys(cliniciansForExport[0]),
    },
    (err, csv) => {
      downloadCSV(csv, ResourceLabels[Resources.CLINICIANS]);
    },
  );
};

const clinicianFilters = [
  <TextInput label={clinicianFieldNames.id.admin} source={clinicianFieldNames.id.db} alwaysOn />,
  <TextInput label={clinicianFieldNames.fullName.admin} source={clinicianFieldNames.fullName.db} alwaysOn />,
];

export const ClinicianList = props => {
  const { resource } = useListController(props);
  useListOptions(ClinicianContext, props);
  const classes = useStyles();

  useListOptions(ClinicianContext, props);
  return (
    <SelectableList
      {...props}
      exporter={exporter}
      filters={clinicianFilters}
      bulkActionButtons={
        <>
          <DeleteManyDialog {...deleteManyDialogOptions[Resources.CLINICIANS]} />
        </>
      }
      title={<Header resource={resource} />}
    >
      <SelectableDatagrid rowClick="show" className={classes.overflowColumn}>
        <TextField source={clinicianFieldNames.id.db} />
        <TextField label={clinicianFieldNames.fullName.admin} source={clinicianFieldNames.fullName.db} />
        <TextField label={clinicianFieldNames.email.admin} source="providerId" />
        <FunctionField
          label="Organizations"
          render={record =>
            (record.HCProviders || [])
              .map(hcp => hcp?.name)
              .filter(i => i)
              .join(',')
          }
        />
      </SelectableDatagrid>
    </SelectableList>
  );
};
