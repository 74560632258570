import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query threadModeratorInvites($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: threadModeratorInvites(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
      name
      email
      status
      expireAt
      updatedAt
    }
    total: threadModeratorInvites(where: $where) {
      id
    }
  }
`;
