import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query threadModerators($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: threadModerators(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
      email
      firstName
      lastName
      credentials
      updatedAt
    }
    total: threadModerators(where: $where) {
      id
    }
  }
`;

export const CREATE = gql`
  mutation createThreadModeratorInvite($threadModerator: ThreadModeratorInviteCreate!) {
    data: createThreadModeratorInvite(data: $threadModerator) {
      id
      email
    }
  }
`;
