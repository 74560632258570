import { FC, useContext, useEffect } from 'react';
import { Datagrid, DatagridProps, Identifier, useListContext, useRecordSelection } from 'react-admin';

import { Checkbox, makeStyles, Paper, Typography } from '@material-ui/core';

import { ResourcesContext } from 'types';

import { SelectableDatagridBody } from '.';

const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    border: '1px solid lightgrey',
  },
});

const createSetSelectedIds =
  (selectFunc, excludedArr, isSelectedAll) =>
  (newIds: Identifier[]): void => {
    const ids = isSelectedAll ? newIds.filter(id => excludedArr.every(excluded => id !== excluded)) : newIds;

    selectFunc(ids);
  };

const SelectableDatagrid: FC<DatagridProps> = props => {
  const classes = useStyles();
  const { resource } = props;
  const { excluded, setExcluded, isSelectedAll, setIsSelectedAll } = useContext(ResourcesContext[resource || '']);
  const { selectedIds } = useListContext(props);
  const [, { select }] = useRecordSelection(resource || '');
  const setSelectedIds = createSetSelectedIds(select, excluded, isSelectedAll);

  const globalCheckboxHandler = (checked: boolean) => {
    setIsSelectedAll(checked);
    if (!checked) {
      setSelectedIds([]);
      setExcluded([]);
    }
  };
  useEffect(() => {
    selectedIds && select(selectedIds);
  }, [select, selectedIds]);

  return (
    <>
      <Paper className={classes.checkboxContainer}>
        <Checkbox onChange={(e, checked) => globalCheckboxHandler(checked)} value={isSelectedAll} />
        <Typography variant="body1">
          Select all {isSelectedAll && ' mode enabled! Every bulk operation will be applied for whole list of items'}
        </Typography>
      </Paper>
      <Datagrid
        {...props}
        body={<SelectableDatagridBody setSelectedIds={setSelectedIds} selectedIds={selectedIds} />}
      />
    </>
  );
};

export default SelectableDatagrid;
