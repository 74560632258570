import { useContext, useState } from 'react';
import {
  Button,
  Confirm,
  useMutation,
  useNotify,
  useRecordContext,
  useRecordSelection,
  useRefresh,
  useResourceContext,
} from 'react-admin';

import { useError } from 'hooks/useError';
import { ResourcesContext } from 'types';

enum DialogTypes {
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
}

interface GetDialogContentProps {
  resource: string;
  dialogType: DialogTypes;
  itemsNumber: number;
  id?: number;
}

interface DialogContent {
  confirm: string;
  title: string;
  content: string;
  notifySuccess: string;
}

interface BulkActionDialogProps {
  publishButtonProps?: object;
  unpublishButtonProps?: object;
}

const getDialogContent = ({ resource, dialogType, itemsNumber, id }: GetDialogContentProps): DialogContent => {
  const updatingObject = id || itemsNumber + ' ' + resource;
  switch (dialogType) {
    case DialogTypes.PUBLISH:
      return {
        confirm: 'Publish',
        title: 'Publish',
        content: `Are you sure you want to publish ${updatingObject} to all?`,
        notifySuccess: `${updatingObject} will be published to all platforms. Are you sure?`,
      };
    case DialogTypes.UNPUBLISH:
      return {
        confirm: 'Unpublish',
        title: 'Unpublish',
        content: `Are you sure you want to unpublish ${updatingObject} to all?`,
        notifySuccess: `${updatingObject} unpublished to all platforms`,
      };
  }
};

const BulkActionDialog = ({ publishButtonProps = {}, unpublishButtonProps = {} }: BulkActionDialogProps) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { isSelectedAll, setIsSelectedAll, excluded, setExcluded } = useContext(ResourcesContext[resource || '']);
  const [selectedIds, { clearSelection }] = useRecordSelection(resource);
  const refresh = useRefresh();
  const notify = useNotify();
  const errorNotify = useError();

  const [dialogType, setDialogType] = useState<DialogTypes.PUBLISH | DialogTypes.UNPUBLISH>(DialogTypes.UNPUBLISH);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [publishedAt, setPublishedAt] = useState<Date | null>(null);

  const { notifySuccess, ...dialogProps } = getDialogContent({
    resource,
    dialogType,
    itemsNumber: selectedIds.length,
    id: record?.uid,
  });

  const idsToUpdate = record?.id ? [record?.id] : selectedIds;

  const [updateMany, { loading }] = useMutation(
    {
      type: 'updateMany',
      resource,
      payload: {
        ids: isSelectedAll ? excluded : idsToUpdate,
        publishedAt,
        isExclude: isSelectedAll,
      },
    },
    {
      onSuccess: () => {
        if (publishedAt) {
          notify(notifySuccess, 'info', undefined, true);
        } else {
          notify(notifySuccess);
        }
        setIsSelectedAll(false);
        setExcluded([]);
        refresh();
        clearSelection();
      },
      onFailure: e => {
        errorNotify(e.message, 'warning');
        refresh();
      },
      mutationMode: publishedAt ? 'undoable' : undefined,
    },
  );

  const btnClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDialogType(() => (e.target as HTMLButtonElement).textContent as DialogTypes);
    setOpenDialog(true);
    setPublishedAt(() => ((e.target as HTMLButtonElement).textContent === DialogTypes.PUBLISH ? new Date() : null));
  };

  const dialogCloseHandler = () => setOpenDialog(false);

  const confirmHandler = () => {
    updateMany();
    dialogCloseHandler();
  };

  return (
    <>
      <Button {...publishButtonProps} label={DialogTypes.PUBLISH} onClick={btnClickHandler} />
      <Button {...unpublishButtonProps} label={DialogTypes.UNPUBLISH} onClick={btnClickHandler} />
      <Confirm
        {...dialogProps}
        isOpen={openDialog}
        loading={loading}
        onConfirm={confirmHandler}
        onClose={dialogCloseHandler}
      />
    </>
  );
};

export default BulkActionDialog;
