import { Create, required, SimpleForm, TextInput, useNotify, useRedirect, useRefresh } from 'react-admin';

import CreateInviteToolbar from 'components/toolbars/CreateInviteToolbar';
import { THREAD_MODERATOR_INVITES_ROUTE } from 'constants/routes';

import { threadModeratorInviteFields } from './types';

interface CreateThreadModeratorInviteData {
  email: string;
}

export const ThreadModeratorInviteCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = ({ data: { email } }: { data: CreateThreadModeratorInviteData }) => {
    notify(`Invite was sent to ${email}`);
    redirect(THREAD_MODERATOR_INVITES_ROUTE);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CreateInviteToolbar />}>
        <TextInput
          label={threadModeratorInviteFields.name.admin}
          source={threadModeratorInviteFields.name.db}
          validate={required()}
        />
        <TextInput
          label={threadModeratorInviteFields.email.admin}
          source={threadModeratorInviteFields.email.db}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
