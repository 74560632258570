export interface PointOfContact {
  id: number;
  isPrimary: boolean;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  phone: string;
  __typename: string;
}

export interface HCProvider {
  id: number;
  name: string;
  isDefault: boolean;
  registrationHash: string;
  primaryPOC: PointOfContact;
  secondaryPOC: PointOfContact;
  __typename: string;
}

export const hcProviderFieldNames = {
  id: { db: 'id', admin: 'ID' },
  name: { db: 'name', admin: 'Name' },
  providerId: { db: 'providerId', admin: 'email' },
  reference: { admin: 'Organization' },
  registrationLink: { db: 'registrationHash', admin: 'Registration Link' },
  primaryPOC: {
    db: 'primaryPOC',
    id: { db: 'primaryPOC.id' },
    isPrimary: { db: 'primaryPOC.isPrimary' },
    firstName: { db: 'primaryPOC.firstName', admin: 'First Name' },
    lastName: { db: 'primaryPOC.lastName', admin: 'Last Name' },
    role: { db: 'primaryPOC.role', admin: 'Role' },
    email: { db: 'primaryPOC.email', admin: 'Email' },
    phone: { db: 'primaryPOC.phone', admin: 'Phone Number' },
  },
  secondaryPOC: {
    db: 'secondaryPOC',
    id: { db: 'secondaryPOC.id' },
    isPrimary: { db: 'secondaryPOC.isPrimary' },
    firstName: { db: 'secondaryPOC.firstName', admin: 'First Name' },
    lastName: { db: 'secondaryPOC.lastName', admin: 'Last Name' },
    role: { db: 'secondaryPOC.role', admin: 'Role' },
    email: { db: 'secondaryPOC.email', admin: 'Email' },
    phone: { db: 'secondaryPOC.phone', admin: 'Phone Number' },
  },
  isDefault: { db: 'isDefault' },
};
