import { DateInput, Edit, SimpleForm, TextField, TextInput } from 'react-admin';

export const PatientEdit = props => (
  <Edit
    {...props}
    mutationMode="optimistic"
    transform={data => {
      const { id, avatarUrl, shortDescription, longDescription, title, name, dob, location, phone } = data?.profile;
      const transformed = {
        profile: {
          id: parseInt(id),
          avatarUrl,
          shortDescription,
          longDescription,
          title,
          name,
          dob: dob || null,
          location,
          phone,
        },
      };
      return transformed;
    }}
  >
    <SimpleForm>
      <TextField source="id" />
      <TextField label="Email" source="providerId" />
      <TextInput label="Name" source="profile.name" />
      <TextInput label="Title" source="profile.title" />
      <DateInput label="DOB" source="profile.dob" />
      <TextInput label="Location" source="profile.location" />
      <TextInput label="Phone" source="profile.phone" />
    </SimpleForm>
  </Edit>
);
