import { ClinicianContext } from 'context/clinician';
import { HCProviderContext } from 'context/hcProvider';
import { LoticUserContext } from 'context/loticUser';
import { PatientContext } from 'context/patient';
import { ThreadModeratorContext } from 'context/threadModerator';
import {
  GET_CLINICIAN_LIST,
  GET_HC_PROVIDER_LIST,
  GET_LOTIC_USER_LIST,
  GET_MARKETING_USER_LIST,
  GET_PACKAGE_SEQUENCE_LIST,
  GET_PATIENT_LIST,
} from 'data/queries/lists';

export enum Resources {
  HC_PROVIDERS = 'HCProviders',
  PATIENTS = 'Patients',
  CLINICIANS = 'Clinicians',
  MARKETING_WEBSITE_USERS = 'MarketingWebsitesUsers',
  PACKAGE_SEQUENCES = 'PackageSequences',
  ONBOARDING_SURVEYS = 'OnboardingSurveys',
  LOTIC_USERS = 'LoticUsers',
  THREAD_MODERATORS = 'ThreadModerators',
  THREAD_MODERATOR_INVITES = 'ThreadModeratorInvites',
}

export const ResourceLabels = {
  [Resources.PACKAGE_SEQUENCES]: 'Sequences',
  [Resources.MARKETING_WEBSITE_USERS]: 'Marketing Websites Users',
  [Resources.HC_PROVIDERS]: 'Orgs',
  [Resources.PATIENTS]: 'Patients',
  [Resources.CLINICIANS]: 'Clinicians',
  [Resources.ONBOARDING_SURVEYS]: 'Onboarding Surveys',
  [Resources.THREAD_MODERATORS]: 'Thread Moderators',
  [Resources.THREAD_MODERATOR_INVITES]: 'Thread Moderators Invites',
};

export const ResourceGetLists = {
  [Resources.PACKAGE_SEQUENCES]: GET_PACKAGE_SEQUENCE_LIST,
  [Resources.HC_PROVIDERS]: GET_HC_PROVIDER_LIST,
  [Resources.PATIENTS]: GET_PATIENT_LIST,
  [Resources.CLINICIANS]: GET_CLINICIAN_LIST,
  [Resources.MARKETING_WEBSITE_USERS]: GET_MARKETING_USER_LIST,
  [Resources.LOTIC_USERS]: GET_LOTIC_USER_LIST,
};

export interface CombinedItem {
  uid: string;
  name: string;
  publishedAt?: Date;
}

export interface Choice {
  id: string;
  uid: string;
  name: string;
}

export enum LocalStorage {
  LIST_CONTEXT = 'listContext',
}

export type Sort = {
  field: string;
  order: string;
};

export enum UidLetters {
  MOMENT = 'M',
  REVIEW = 'R',
  LEARN = 'H',
  WIND_DOWN = 'W',
  DAILY_PRACTICE = 'U',
  CHECK_IN = '',
  CONTEXT = 'K',
  QUESTION = 'C',
  PACKAGE = 'P',
  PACKAGE_SEQUENCE = 'S',
  WISDOM_ENGINE_PROMPT = 'M',
}

export const ResourcesContext = {
  [Resources.PATIENTS]: PatientContext,
  [Resources.HC_PROVIDERS]: HCProviderContext,
  [Resources.CLINICIANS]: ClinicianContext,
  [Resources.LOTIC_USERS]: LoticUserContext,
  [Resources.THREAD_MODERATORS]: ThreadModeratorContext,
};

export enum LoticUserRole {
  MASTER = 'master',
  CONTENT_EDITOR = 'contentEditor',
}
