import { Sidebar as RaSidebar, ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  open: {
    paddingRight: 5,
  },
});

const Sidebar = props => {
  const classes = useStyles();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  return (
    <RaSidebar
      className={classNames({
        [classes.open]: open,
      })}
      {...props}
    />
  );
};

export default Sidebar;
