enum Key {
  EMAIL = 'email',
}

export interface GoogleUser {
  [Key.EMAIL]: string;
}

export const GOOGLE_USER: GoogleUser = {
  [Key.EMAIL]: '',
};
