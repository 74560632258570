import { FC } from 'react';

import { Typography, TypographyProps } from '@material-ui/core';

const PlainTextField: FC<TypographyProps> = ({ children, classes, variant }) => (
  <Typography classes={classes} variant={variant}>
    {children}
  </Typography>
);

export default PlainTextField;
