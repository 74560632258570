import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LinearProgress from '@mui/material/LinearProgress';

import { Auth } from 'components/layout/Auth';
import { ReactAdmin } from 'components/layout/ReactAdmin';
import { useAppContext } from 'context/appContext';
import { useSignedLogin } from 'hooks/useGoogleAuth';

import './App.css';

const App: FC = () => {
  const { app, user, setUser, setApp } = useAppContext();

  useSignedLogin({ app, user, setUser, setApp });

  return (
    <div className="app-container">
      {app.isLoaded && user.email && <ReactAdmin />}
      {app.isLoaded && !user.email && <Auth />}
      {!app.isLoaded && <LinearProgress className="progress-loader" />}
      <ToastContainer />
    </div>
  );
};

export default App;
