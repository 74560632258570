import { makeStyles } from '@material-ui/core/styles';

import PlainTextField from 'components/fields/PainTextField';
import { ResourceLabels, Resources } from 'types';

import CustomMenuItem from './CustomMenuItem';

const useStyles = makeStyles({
  subtitle: {
    paddingLeft: '16px',
    paddingTop: '16px',
  },
});

const MAX_LABEL_LENGTH = 22;

/**
 * Creates menu items for passed array of resources using `CustomMenuItem` component for each
 *
 * @param resources array of resource names
 * @returns `React` component representing list of menu items for passed resources
 */
const buildMenuItems = (resources: Resources[]) => (
  <>
    {resources.map(resource => {
      const resourceLabel: string = ResourceLabels[resource] || resource;
      const menuLabel =
        resourceLabel.length < MAX_LABEL_LENGTH ? resourceLabel : resourceLabel.slice(0, MAX_LABEL_LENGTH) + '...';

      return <CustomMenuItem key={resource} resource={resource} label={menuLabel} />;
    })}
  </>
);

interface MenuSectionProps {
  sectionName: string;
  resources: Resources[];
}

const MenuSection = ({ sectionName, resources }: MenuSectionProps) => {
  const classes = useStyles();
  return (
    <>
      <PlainTextField classes={{ root: classes.subtitle }} variant="subtitle1">
        {sectionName}
      </PlainTextField>
      {buildMenuItems(resources)}
    </>
  );
};

export default MenuSection;
