import { ReactElement, useContext, useEffect } from 'react';
import { List, ListProps, useListController } from 'react-admin';

import { RECORDS_PER_PAGE } from 'constants/common';
import { ResourcesContext } from 'types';

const SelectableList = (props: ListProps & { children: ReactElement }): ReactElement => {
  const { resource } = props;

  // @ts-ignore
  const { isSelectedAll, excluded } = useContext(ResourcesContext[resource || '']);
  const { selectedIds, total } = useListController(props);

  if (isSelectedAll) {
    // we need selectedIds only for showing it length
    // because in global operations mode we work with excluded array only
    selectedIds.length = total - excluded.length;
  }

  useEffect(() => {
    if (!isSelectedAll) {
      selectedIds.length = 0;
    }
  }, [isSelectedAll]);

  return (
    <List perPage={RECORDS_PER_PAGE} {...props}>
      {props.children}
    </List>
  );
};

export default SelectableList;
