import { FC } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { DataTable } from 'components/DataTable/DataTable';
import { COIN_TRANSACTIONS, COIN_TRANSACTIONS_COUNT } from 'data/queries/coinTransactions';
import { LONG_REQUEST_TIMEOUT } from 'utils/const';

import { patientFields } from '../types';

interface Props {
  patientId: number;
}

const columns: GridColDef[] = [
  {
    headerName: patientFields.coinTransactions.createdAt.admin,
    field: patientFields.coinTransactions.createdAt.db,
    filterable: false,
    valueFormatter: ({ value }) => new Date(value).toLocaleString('en-US'),
    width: 200,
  },
  {
    headerName: patientFields.coinTransactions.activity.admin,
    field: patientFields.coinTransactions.activity.db,
    filterable: false,
    width: 300,
  },
  {
    headerName: patientFields.coinTransactions.amount.admin,
    field: patientFields.coinTransactions.amount.db,
    filterable: false,
  },
];

export const CoinTransactionsTable: FC<Props> = ({ patientId }) => (
  <DataTable
    dataQuery={{
      query: COIN_TRANSACTIONS,
      options: {
        variables: { where: { patientId } },
        context: { timeout: LONG_REQUEST_TIMEOUT },
      },
    }}
    totalQuery={{
      query: COIN_TRANSACTIONS_COUNT,
      options: { variables: { where: { patientId } } },
    }}
    dataGridProps={{ sortModel: [{ field: 'createdAt', sort: 'desc' }] }}
    columns={columns}
    perPage={10}
  />
);
