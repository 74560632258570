import { FunctionField, Record, Show, SimpleForm, TextField, useShowController } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PlainTextField from 'components/fields/PainTextField';
import { RegistrationLinkField } from 'components/fields/RegistrationLinkField';
import { Header } from 'components/Header';
import { BaseActionsToolbar } from 'components/toolbars/BaseActionsToolbar';
import { NavigationToolbar } from 'components/toolbars/NavigationToolbar';
import { deleteOneDialogOptions } from 'constants/dialogOptions';
import { HCProviderContext } from 'context/hcProvider';
import { ResourceLabels, Resources } from 'types';

import { hcProviderFieldNames } from './types';

const useStyles = makeStyles({
  subHeader: {
    borderBottom: '2px solid #9eadba',
    marginTop: '2%',
  },
  subGroup: {
    marginTop: '2%',
    marginBottom: '1%',
  },
  disclaimer: {
    textAlign: 'center',
    marginTop: '2%',
    paddingTop: '2%',
    borderTop: '2px solid #9eadba',
  },
  longString: {
    overflowWrap: 'anywhere',
  },
});

export const HCProviderShow = props => {
  const { record, resource } = useShowController(props);
  const classes = useStyles();

  return (
    <Show
      {...props}
      actions={<NavigationToolbar context={HCProviderContext} record={record} resource={resource} />}
      title={<Header resource={ResourceLabels[Resources.HC_PROVIDERS]} id={record?.id} />}
    >
      <SimpleForm
        toolbar={
          <BaseActionsToolbar
            isEditable
            deleteActionProps={{
              ...deleteOneDialogOptions[Resources.HC_PROVIDERS],
              disabled: record?.isDefault,
            }}
          />
        }
      >
        <TextField label={hcProviderFieldNames.id.admin} source={hcProviderFieldNames.id.db} />
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.name.admin}
          source={hcProviderFieldNames.name.db}
        />
        <RegistrationLinkField
          label={hcProviderFieldNames.registrationLink.admin}
          hashFieldName={hcProviderFieldNames.registrationLink.db}
        />
        <PlainTextField classes={{ root: classes.subHeader }} variant="h5">
          Points of Contact
        </PlainTextField>
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Primary
        </PlainTextField>
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.primaryPOC.firstName.admin}
          source={hcProviderFieldNames.primaryPOC.firstName.db}
        />
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.primaryPOC.lastName.admin}
          source={hcProviderFieldNames.primaryPOC.lastName.db}
        />
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.primaryPOC.role.admin}
          source={hcProviderFieldNames.primaryPOC.role.db}
        />
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.primaryPOC.email.admin}
          source={hcProviderFieldNames.primaryPOC.email.db}
        />
        <TextField
          className={classes.longString}
          label={hcProviderFieldNames.primaryPOC.phone.admin}
          source={hcProviderFieldNames.primaryPOC.phone.db}
        />
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Secondary
        </PlainTextField>
        <FunctionField
          className={classes.longString}
          label={hcProviderFieldNames.secondaryPOC.firstName.admin}
          render={(record?: Record) => record?.secondaryPOC?.firstName || '--'}
        />
        <FunctionField
          className={classes.longString}
          label={hcProviderFieldNames.secondaryPOC.lastName.admin}
          render={(record?: Record) => record?.secondaryPOC?.lastName || '--'}
        />
        <FunctionField
          className={classes.longString}
          label={hcProviderFieldNames.secondaryPOC.role.admin}
          render={(record?: Record) => record?.secondaryPOC?.role || '--'}
        />
        <FunctionField
          className={classes.longString}
          label={hcProviderFieldNames.secondaryPOC.email.admin}
          render={(record?: Record) => record?.secondaryPOC?.email || '--'}
        />
        <FunctionField
          className={classes.longString}
          label={hcProviderFieldNames.secondaryPOC.phone.admin}
          render={(record?: Record) => record?.secondaryPOC?.phone || '--'}
        />
        {record?.isDefault && (
          <PlainTextField classes={{ root: classes.disclaimer }}>
            Note, that this organization is a default organization and, thus, cannot be removed from the system
          </PlainTextField>
        )}
      </SimpleForm>
    </Show>
  );
};
