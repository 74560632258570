import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query allWebUsers {
    data: allUsers {
      id
      name
      email
      sendNews
      source
    }
    total: allUsers {
      id
    }
  }
`;
