enum Key {
  IS_LOADED = 'isLoaded',
}

export interface Application {
  [Key.IS_LOADED]: boolean;
}

export const APP: Application = {
  [Key.IS_LOADED]: false,
};
