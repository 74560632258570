import { FC } from 'react';
import { useDelete, useNotify, useRecordContext, useRedirect, useResourceContext } from 'react-admin';

import { Typography } from '@material-ui/core';
import _get from 'lodash/get';

import { useError } from 'hooks/useError';

import BaseDeleteDialog from './BaseDeleteDialog';

export interface DeleteOneDialogProps {
  resourceLabel: string;
  title: string;
  recordNamePath: string;
  disabled?: boolean;
}

const DeleteOneDialog: FC<DeleteOneDialogProps> = ({ resourceLabel, title, recordNamePath, disabled }) => {
  const notify = useNotify();
  const errorNotify = useError();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const record = useRecordContext();
  const recordName = _get(record, recordNamePath, '');

  const [deleteOne, { loading }] = useDelete(resource, record?.id, record, {
    onSuccess: () => {
      notify(`${resourceLabel} deleted`, 'info');
      redirect('list', `/${resource}`);
    },
    onFailure: error => errorNotify(error.message, 'warning'),
  });

  const basicDialogContent = (
    <Typography>
      {`Are you sure you want to\ndelete the ${resourceLabel}:\n`}
      <strong>{recordName}</strong>
    </Typography>
  );

  const extraWarningDialogContent = (
    <Typography color="secondary">
      {`By confirming this action you will permanently\ndelete the ${resourceLabel}`} <strong>{recordName}</strong>
      {` from the system. Are you sure?`}
    </Typography>
  );

  return (
    <BaseDeleteDialog
      title={title}
      loading={loading}
      disabled={disabled}
      basicDialogContent={basicDialogContent}
      extraWarningDialogContent={extraWarningDialogContent}
      deleteHandler={deleteOne}
    />
  );
};

export default DeleteOneDialog;
