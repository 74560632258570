import { gql } from '@apollo/client';

export const GET_ONBOARDING_SURVEY_SUBMISSIONS = gql`
  query onboardingSurveySubmissions($order: String, $offset: Int, $limit: Int, $where: JSON) {
    data: onboardingSurveySubmissions(order: $order, offset: $offset, limit: $limit, where: $where) {
      id: answerDate
      answerDate
      answers {
        id
        count
      }
    }
  }
`;
export const GET_ONBOARDING_SURVEY_SUBMISSIONS_COUNT = gql`
  query onboardingSurveySubmissionDatesCount {
    total: onboardingSurveySubmissionDatesCount
  }
`;
