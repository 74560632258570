import { downloadCSV, FunctionField, TextField, TextInput, useListController } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

import BulkActionDialog from 'components/dialogs/BulkActionDialog';
import { Header } from 'components/Header';
import { SelectableDatagrid, SelectableList } from 'components/SelectableList';
import { LoticUserContext } from 'context/loticUser';
import { useListOptions } from 'hooks/useListOptions';
import { Resources } from 'types';

import { LoticUser, LoticUserFields, Roles } from './types';

const exporter = (loticUsers: LoticUser[]) => {
  const loticUsersForExport = loticUsers.map(({ providerId, role, profile }: LoticUser) => ({
    [LoticUserFields.name.admin]: profile.name,
    [LoticUserFields.email.admin]: providerId,
    [LoticUserFields.title.admin]: profile.title,
    [LoticUserFields.role.admin]: role,
  }));
  jsonExport(
    loticUsersForExport,
    {
      headers: Object.keys(loticUsersForExport[0]),
    },
    (err, csv) => {
      downloadCSV(csv, Resources.LOTIC_USERS);
    },
  );
};

const loticUsersFilters = [
  <TextInput label={LoticUserFields.name.admin} source={LoticUserFields.name.db} alwaysOn />,
  <TextInput label={LoticUserFields.email.admin} source={LoticUserFields.email.db} alwaysOn />,
  <TextInput label={LoticUserFields.title.admin} source={LoticUserFields.title.db} alwaysOn />,
  <TextInput label={LoticUserFields.role.admin} source={LoticUserFields.role.db} alwaysOn />,
];

const useStyles = makeStyles({
  overflow: {
    '& .column-displayName': { overflowWrap: 'anywhere' },
    '& .column-area': { overflowWrap: 'anywhere' },
  },
});

export const LoticUserList = props => {
  const { resource } = useListController(props);
  useListOptions(LoticUserContext, props);
  const classes = useStyles();

  return (
    <SelectableList
      {...props}
      filters={loticUsersFilters}
      exporter={exporter}
      bulkActionButtons={<BulkActionDialog />}
      title={<Header resource={resource} />}
    >
      <SelectableDatagrid rowClick="show" className={classes.overflow}>
        <TextField label={LoticUserFields.name.admin} source={LoticUserFields.name.db} sortable={false} />
        <TextField label={LoticUserFields.email.admin} source={LoticUserFields.email.db} />
        <TextField label={LoticUserFields.title.admin} source={LoticUserFields.title.db} />
        <FunctionField label={LoticUserFields.role.admin} render={record => Roles[record.role]} />
      </SelectableDatagrid>
    </SelectableList>
  );
};
