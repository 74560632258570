import { Context, useContext, useEffect } from 'react';
import { useListController } from 'react-admin';

import { ListProps } from 'ra-core/esm/controller/useListController';

import { ContextValues } from 'context';

export const useListOptions = (context: Context<ContextValues>, props: ListProps) => {
  const { page, setPage, currentSort } = useListController(props);
  const { currentPage, setCurrentPage, setCurrentSort } = useContext(context);

  useEffect(() => {
    setCurrentSort(currentSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSort]);

  useEffect(() => {
    if (page !== currentPage) {
      setPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
};
