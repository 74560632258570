export const usersFieldNames = {
  name: { db: 'name', admin: 'First name' },
  email: { db: 'email', admin: 'Email address' },
  sendNews: { db: 'sendNews', admin: 'Opt-in for newsletters' },
  source: { db: 'source', admin: 'Source' },
};

export interface User {
  name: string;
  email: string;
  sendNews: boolean;
  source: string;
}
