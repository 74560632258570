import { FC } from 'react';
import { MenuItemLink, MenuItemLinkProps } from 'react-admin';

import DefaultIcon from '@material-ui/icons/ViewList';

interface Props extends Omit<MenuItemLinkProps, 'to'> {
  label: string;
}
const CustomMenuItem: FC<Props> = ({ resource, label, ...props }) => (
  <MenuItemLink
    {...props}
    key={resource}
    to={{
      pathname: `/${resource}`,
    }}
    primaryText={label}
    leftIcon={<DefaultIcon />}
  />
);

export default CustomMenuItem;
