import { useContext, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  Confirm,
  ReferenceInput,
  SimpleForm,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { DeleteManyDialog } from 'components/dialogs/deleteDialogs';
import { deleteManyDialogOptions } from 'constants/dialogOptions';
import Content from 'contents/patient/list.json';
import { useError } from 'hooks/useError';
import { PackageSequence } from 'resources/packageSequence/types';
import { Resources, ResourcesContext } from 'types';
import { renderOptionText } from 'utils';
import { buildPublishedAtFilter } from 'utils/filters';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    width: 400,
  },
  button: {
    padding: '9px 22px',
    margin: '0 5px',
  },
  label: {
    textTransform: 'none',
    fontSize: 14,
  },
});

export const BulkAssignSequenceDialog = ({ resource, ...props }) => {
  const { selectedIds } = props;
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const errNotify = useError();
  const unselectAll = useUnselectAll();
  const { isSelectedAll, setIsSelectedAll, excluded, setExcluded } = useContext(ResourcesContext[resource || '']);

  const [packageSequenceId, setPackageSequenceId] = useState<number | null>(null);
  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);

  const [updateMany] = useMutation(
    {
      type: 'updateMany',
      resource,
      payload: {
        ids: isSelectedAll ? excluded : selectedIds,
        packageSequenceId,
        isExclude: isSelectedAll,
      },
    },
    {
      onSuccess: () => {
        notify(Content.bulkAssignSequenceToolbar.notifications.onPublish, 'info', undefined, true);
        setIsSelectedAll(false);
        setExcluded([]);
        refresh();
        unselectAll(resource);
      },
      onFailure: e => {
        errNotify(e.message, 'warning');
      },
      mutationMode: 'undoable',
    },
  );

  const publishHandler = () => {
    if (packageSequenceId) {
      setOpenPublishDialog(true);
    } else {
      notify(Content.bulkAssignSequenceToolbar.notifications.missSequence, 'info');
    }
  };

  return (
    <div className={classes.container}>
      <SimpleForm toolbar={<></>}>
        <ReferenceInput
          onChange={value => setPackageSequenceId(value)}
          label={Content.bulkAssignSequenceToolbar.inputs.sequence}
          source="id"
          reference={Resources.PACKAGE_SEQUENCES}
          filterToQuery={(searchText: string) => ({
            title: searchText,
          })}
          className={classes.field}
          filter={buildPublishedAtFilter(true)}
          helperText={false}
        >
          <AutocompleteInput optionText={({ uid, title }: PackageSequence) => renderOptionText(uid, title)} />
        </ReferenceInput>
      </SimpleForm>
      <Button
        label={Content.bulkAssignSequenceToolbar.buttons.publish}
        onClick={publishHandler}
        className={classes.button}
        variant="contained"
        classes={{ label: classes.label }}
      />
      <DeleteManyDialog {...deleteManyDialogOptions[Resources.PATIENTS]} />
      <Confirm
        confirm={Content.bulkAssignSequenceToolbar.publishPopup.buttons.confirm}
        title={Content.bulkAssignSequenceToolbar.publishPopup.title}
        content={Content.bulkAssignSequenceToolbar.publishPopup.text}
        isOpen={openPublishDialog}
        onConfirm={() => updateMany()}
        onClose={() => setOpenPublishDialog(false)}
      />
    </div>
  );
};
