export interface LoticUser {
  id: number;
  providerId: string;
  role: string;
  profile: {
    name: string;
    title: string;
  };
}

export enum Roles {
  master = 'Master',
  contentEditor = 'Editor',
  reader = 'Read',
}

export const LoticUserFields = {
  id: { db: 'id', admin: 'ID' },
  name: { db: 'profile.name', admin: 'Name' },
  email: { db: 'providerId', admin: 'Email' },
  title: { db: 'profile.title', admin: 'Role' },
  role: { db: 'role', admin: 'Access' },
};
