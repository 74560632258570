import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation inviteClinicianByAdmin($clinician: ClinicianCreateByAdmin!) {
    data: inviteClinicianByAdmin(clinician: $clinician) {
      id
      message
    }
  }
`;

export const GET_ONE = gql`
  query clinician($id: Int!) {
    data: clinician(id: $id) {
      id
      providerId
      resetPasswordCode
      resetPasswordCodeExpireAt
      primaryEmail {
        email
      }
      HCProviders {
        name
        id
      }
      patients {
        id
        profile {
          name
        }
        providerId
      }
      profile {
        id
        avatarUrl
        shortDescription
        longDescription
        title
        name
        dob
        location
        phone
      }
    }
  }
`;
export const GET_LIST = gql`
  query clinicians($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: clinicians(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
      providerId
      primaryEmail {
        email
      }
      HCProviders {
        name
      }
      profile {
        id
        avatarUrl
        shortDescription
        longDescription
        title
        name
        dob
        location
        phone
      }
    }
    total: clinicians {
      id
    }
  }
`;
export const GET_MANY = GET_LIST;
export const UPDATE = gql`
  mutation updateClinician($clinician: ClinicianUpdate) {
    data: updateClinician(clinician: $clinician) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation destroyClinician($clinician: ClinicianDestroy!) {
    data: destroyClinician(clinician: $clinician) {
      id
    }
  }
`;

export const DELETE_MANY = gql`
  mutation bulkDestroyClinicians($clinicians: CliniciansBulkDestroy) {
    data: bulkDestroyClinicians(clinicians: $clinicians)
  }
`;
