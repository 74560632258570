export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const TOKEN_KEY = 'token';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const REQUEST_TIMEOUT = 10000;
export const LONG_REQUEST_TIMEOUT = 100000;
