import { FC } from 'react';
import { EditButton, Toolbar, ToolbarProps } from 'react-admin';

import EditIcon from '@material-ui/icons/Edit';

import { DeleteOneDialog, DeleteOneDialogProps } from 'components/dialogs/deleteDialogs';

type BaseActionsToolbarProps = ToolbarProps & {
  isEditable: boolean;
  deleteActionProps: DeleteOneDialogProps;
};

export const BaseActionsToolbar: FC<BaseActionsToolbarProps> = ({ isEditable, deleteActionProps, ...props }) => {
  return (
    <Toolbar {...props}>
      {isEditable && (
        <EditButton icon={<EditIcon />} variant="outlined" color="inherit" label="EDIT" submitOnEnter={false} />
      )}
      <DeleteOneDialog {...deleteActionProps} />
    </Toolbar>
  );
};
