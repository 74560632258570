import { FC } from 'react';
import { Confirm, Record, useNotify, useRefresh } from 'react-admin';

import { Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Close } from '@material-ui/icons';
import axios from 'axios';

import dialogTheme from 'themes/dialogTheme';
import config from 'utils/config';

interface ResetPasswordDialogProps {
  record?: Record;
  openDialog: boolean;
  onClose: () => void;
  confirmBtnName: string;
  title: string;
  content: string;
}

const useStyles = makeStyles(theme => ({
  dialogConfirmBtn: {
    color: theme.palette.common.white,
    backgroundColor: deepPurple.A400,
    border: `1px solid`,
    borderColor: deepPurple.A400,
    marginLeft: 20,

    '&:hover': {
      backgroundColor: fade(deepPurple.A400, 0.7),
      borderColor: fade(deepPurple.A400, 0),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const ResetPasswordDialog: FC<ResetPasswordDialogProps> = ({
  record,
  openDialog,
  onClose,
  confirmBtnName,
  title,
  content,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = async (email: string) => {
    axios.post(`${config.api.baseUrl}/auth/clinician/resetPassword/${email}`).then(res => console.log(res));
  };

  const confirmHandler = async (email: string) => {
    await handleClick(email);
    refresh();
    onClose();
    notify(`Reset Password code sent to ${record?.providerId}`);
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Confirm
        confirm={confirmBtnName}
        title={title}
        content={
          <Typography>
            {content}
            <Close onClick={onClose} />
          </Typography>
        }
        isOpen={openDialog}
        //loading={loading}
        onConfirm={() => confirmHandler(record?.providerId)}
        onClose={onClose}
        classes={{ confirmPrimary: classes.dialogConfirmBtn }}
      />
    </ThemeProvider>
  );
};

export default ResetPasswordDialog;
