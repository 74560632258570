import { toast } from 'react-toastify';

import { useGoogleLogin } from '@react-oauth/google';

import { useAppContext } from 'context/appContext';
import { Application } from 'context/appContext/app';
import { GOOGLE_USER, GoogleUser } from 'context/appContext/user';
import { LoticUserRole } from 'types';

const API_HOST = String(process.env.REACT_APP_API_HOST);
const CLIENT_LOGIN_PATH = '#/login';
const GOOGLE_LOG_IN_PATH = '/auth/google/login';
const GOOGLE_LOG_OUT_PATH = '/auth/google/logout';
const PROVIDER_ID = 'providerId';

const replaceToLogin = (): void => window.history.replaceState({}, '', CLIENT_LOGIN_PATH);

interface UseSignedLogin {
  app: Application;
  user: GoogleUser;
  setUser: React.Dispatch<React.SetStateAction<GoogleUser>>;
  setApp: React.Dispatch<React.SetStateAction<Application>>;
}
export const useSignedLogin = ({ app, user, setUser, setApp }: UseSignedLogin) => {
  if (!user.email && !app.isLoaded) {
    const providerId = localStorage.getItem(PROVIDER_ID);
    setApp({ ...app, isLoaded: true });
    if (providerId) {
      setUser({ email: providerId });
    }
  }
};

interface UseLogoutProps {
  user: GoogleUser;
  setUser: React.Dispatch<React.SetStateAction<GoogleUser>>;
}
export const useLogout = ({ user, setUser }: UseLogoutProps) => {
  const signOut = async () => {
    await fetch(API_HOST + GOOGLE_LOG_OUT_PATH);

    if (user.email) {
      setUser(GOOGLE_USER);
      localStorage.removeItem(PROVIDER_ID);
    }

    replaceToLogin();
  };

  return { signOut };
};

export const usePromtLogin = () => {
  const { app, setUser, setApp } = useAppContext();

  replaceToLogin();

  return useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const result = await fetch(API_HOST + GOOGLE_LOG_IN_PATH, {
          method: 'POST',
          credentials: 'include',
          headers: new Headers({
            ...(tokenResponse.code && { Authorization: tokenResponse.code }),
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ role: LoticUserRole.MASTER }),
        }).then(res => res.json());

        localStorage.setItem(PROVIDER_ID, result?.providerId);
        setUser({ email: result?.providerId });
        setApp({ ...app, isLoaded: true });

        if (result.success) {
          toast.success('success');
        } else {
          toast.error('access denied');
        }
      } catch (err) {
        toast.error(err);
      }
    },
    flow: 'auth-code',
  });
};
