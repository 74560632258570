import { FC, ReactNode, useState } from 'react';
import { Button, Confirm } from 'react-admin';

import { pink } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Close, Delete } from '@material-ui/icons';

import dialogTheme from 'themes/dialogTheme';

const useStyles = makeStyles(theme => ({
  deleteBtn: {
    color: theme.palette.error.main,
    border: 'none',
    marginLeft: 'auto',

    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  dialogConfirmBtn: {
    color: theme.palette.common.white,
    backgroundColor: pink[500],
    border: `1px solid`,
    borderColor: pink[500],
    marginLeft: 20,

    '&:hover': {
      backgroundColor: fade(pink[500], 0.7),
      borderColor: fade(pink[500], 0),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

enum DialogTypes {
  BASIC = 'basic',
  EXTRA_WARNING = 'extraWarning',
}

export interface BaseDeleteDialogProps {
  title: string;
  disabled?: boolean;
  loading: boolean;
  basicDialogContent: ReactNode;
  extraWarningDialogContent: ReactNode;
  deleteHandler: () => void;
}

const BaseDeleteDialog: FC<BaseDeleteDialogProps> = ({
  title,
  disabled,
  loading,
  basicDialogContent,
  extraWarningDialogContent,
  deleteHandler,
}) => {
  const classes = useStyles();
  const [currentDialog, setCurrentDialog] = useState<DialogTypes | null>(null);

  /**
   * Creates callback for setting the current dialog via `setCurrentDialog`
   *
   * @param dialogType dialog types or null
   * @returns callback that fires `setCurrentDialog` with passed `dialogType` param
   */
  const setDialog = (dialogType: DialogTypes | null) => () => setCurrentDialog(dialogType);

  /**
   * Callback that fires on confirming the extra warning dialog
   *
   * @returns void
   */
  const confirmExtraWarningDialogHandler = () => {
    deleteHandler();
    setCurrentDialog(null);
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Button
        startIcon={<Delete />}
        label="DELETE"
        onClick={setDialog(DialogTypes.BASIC)}
        submitOnEnter={false}
        className={classes.deleteBtn}
        disabled={disabled}
      />
      <Confirm
        confirm="DELETE"
        title={title}
        content={
          <>
            {basicDialogContent}
            <Close onClick={setDialog(null)} />
          </>
        }
        isOpen={currentDialog === DialogTypes.BASIC}
        loading={loading}
        onConfirm={setDialog(DialogTypes.EXTRA_WARNING)}
        onClose={setDialog(null)}
        classes={{ confirmPrimary: classes.dialogConfirmBtn }}
      />
      <Confirm
        confirm="CONFIRM"
        title={title}
        content={
          <>
            {extraWarningDialogContent}
            <Close onClick={setDialog(null)} />
          </>
        }
        isOpen={currentDialog === DialogTypes.EXTRA_WARNING}
        loading={loading}
        onConfirm={confirmExtraWarningDialogHandler}
        onClose={setDialog(null)}
        classes={{ confirmPrimary: classes.dialogConfirmBtn }}
      />
    </ThemeProvider>
  );
};

export default BaseDeleteDialog;
