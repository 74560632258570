import { FC } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import _get from 'lodash/get';

import { DataTable } from 'components/DataTable/DataTable';
import { MOMENT_SUBMISSIONS, MOMENT_SUBMISSIONS_COUNT } from 'data/queries/momentSubmissions';
import { LONG_REQUEST_TIMEOUT } from 'utils/const';

import { patientFields } from '../types';

interface Props {
  patientId: number;
}

const columns: GridColDef[] = [
  {
    field: 'moment.uid',
    headerName: patientFields.moment.id.admin,
    valueGetter: params => {
      return _get(params.row, patientFields.moment.id.db) || 'Moment deleted';
    },
    sortable: false,
    flex: 1.2,
    filterable: false,
  },
  {
    field: patientFields.moment.createdAt.db,
    headerName: patientFields.moment.createdAt.admin,
    valueFormatter: params => new Date(params.value).toLocaleString('en-US') || '--',
    flex: 1.4,
    filterable: false,
  },
  {
    field: patientFields.moment.type.db,
    headerName: patientFields.moment.type.admin,
    flex: 0.8,
    filterable: false,
  },
  {
    field: patientFields.moment.length.db,
    headerName: patientFields.moment.length.admin,
    valueFormatter: params => dayjs(params.value).format('m:ss'),
    flex: 0.8,
    filterable: false,
  },
];

export const MomentsTable: FC<Props> = ({ patientId }) => (
  <DataTable
    dataQuery={{
      query: MOMENT_SUBMISSIONS,
      options: {
        variables: { where: { patientId } },
        context: { timeout: LONG_REQUEST_TIMEOUT },
      },
    }}
    totalQuery={{
      query: MOMENT_SUBMISSIONS_COUNT,
      options: { variables: { where: { patientId } } },
    }}
    columns={columns}
    dataGridProps={{ rowsPerPageOptions: [5, 10, 25], sortModel: [{ field: 'createdAt', sort: 'desc' }] }}
    perPage={5}
  />
);
