import { gql } from '@apollo/client';

export const CREATE = null;
export const GET_ONE = null;
export const MOMENT_SUBMISSIONS = gql`
  query momentSubmissions($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: momentSubmissions(order: $order, limit: $limit, offset: $offset, where: $where) {
      id: uuid
      type
      createdAt
      durationMs
      moment {
        uid
      }
    }
  }
`;

export const MOMENT_SUBMISSIONS_COUNT = gql`
  query momentsCount($where: JSON) {
    total: momentsCount(where: $where)
  }
`;

export const UPDATE = null;
export const DELETE = null;
