import { gql } from '@apollo/client';

export const GET_ONBOARDING_SURVEY_ANSWERS = gql`
  query onboardingSurveyAnswers {
    answers: onboardingSurveyAnswers {
      id
      answer
    }
  }
`;
