import dayjs from 'dayjs';

import { Sort } from 'types';

import { DATE_FORMAT } from './const';

export const renderDate = (date: Date | string | null) => (date ? dayjs(date).format(DATE_FORMAT) : '--');

export const msToMin = (value: number) => (value ? value / 60 / 1000 : '');
export const minToMs = (value: number) => value * 60 * 1000;

export const buildRegistrationLink = (registrationHash: string): string =>
  `${process.env.REACT_APP_PROVIDER_PORTAL_URL}/register/${registrationHash}`;

export const renderOptionText = (id: number | string, title: string): string => `${id}: ${title}`;

export const buildOrderFromSort = ({ field, order }: Sort) => `${order === 'DESC' ? `reverse:` : ''}${field}`;
