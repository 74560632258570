import { FC, useContext, useEffect } from 'react';
import { DatagridBody, DatagridBodyProps, Identifier } from 'react-admin';

import { ResourcesContext } from 'types';

import { SelectableDatagridRow } from '.';

export interface SelectableDatagridBodyProps extends DatagridBodyProps {
  setSelectedIds: (newIds: Identifier[]) => void;
}

const SelectableDatagridBody: FC<SelectableDatagridBodyProps> = props => {
  const { ids, setSelectedIds, resource } = props;
  const { isSelectedAll } = useContext(ResourcesContext[resource || '']);

  //to keep all selected if isSelectedAll
  useEffect(() => {
    if (ids && isSelectedAll) {
      setSelectedIds(ids);
    }
  }, [ids, setSelectedIds, isSelectedAll]);

  return <DatagridBody {...props} row={<SelectableDatagridRow />} />;
};

export default SelectableDatagridBody;
