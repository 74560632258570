import { Button, useRedirect } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { THREAD_MODERATOR_INVITES_ROUTE } from 'constants/routes';
import { Resources } from 'types';

import { ThreadModeratorInviteList } from './list';

const useStyles = makeStyles(theme => ({
  button: {
    alignSelf: 'flex-start',
    margin: '20px 0',
    backgroundColor: theme.palette.info.main,
  },
}));

export const ThreadModeratorInvitePage = () => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <>
      <Button
        startIcon={<ArrowBackIcon />}
        label="Back"
        onClick={() => redirect(`/${Resources.THREAD_MODERATORS}`)}
        variant="contained"
        submitOnEnter={false}
        className={classes.button}
      />
      <ThreadModeratorInviteList basePath={THREAD_MODERATOR_INVITES_ROUTE} />
    </>
  );
};
