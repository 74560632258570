import { ListButton, SaveButton, Toolbar } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  saveAs: {
    marginLeft: '5px',
    padding: '5px 10px',
  },
  cancel: {
    marginLeft: 'auto',
  },
});

export const BaseEditToolbar = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        variant="outlined"
        key={`${props.pristine}save`}
        disabled={props.pristine}
        submitOnEnter={true}
      />
      <ListButton label="Cancel" color="inherit" icon={<ClearIcon />} className={classes.cancel} />
    </Toolbar>
  );
};
