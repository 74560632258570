import { Datagrid, downloadCSV, List, TextField, TextInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

import { DeleteManyDialog } from 'components/dialogs/deleteDialogs';
import { RegistrationLinkField } from 'components/fields/RegistrationLinkField';
import { Header } from 'components/Header';
import { RECORDS_PER_PAGE } from 'constants/common';
import { deleteManyDialogOptions } from 'constants/dialogOptions';
import { HCProviderContext } from 'context/hcProvider';
import { useListOptions } from 'hooks/useListOptions';
import { ResourceLabels, Resources } from 'types';
import { buildRegistrationLink } from 'utils';

import { HCProvider, hcProviderFieldNames } from './types';

const useStyles = makeStyles({
  overflowColumn: {
    '& .column-name': { overflowWrap: 'anywhere' },
  },
});

const exporter = (hcProviders: HCProvider[]) => {
  const hcProvidersForExport = hcProviders.map(({ id, name, registrationHash }: HCProvider) => ({
    [hcProviderFieldNames.id.admin]: id,
    [hcProviderFieldNames.name.admin]: name,
    [hcProviderFieldNames.registrationLink.admin]: buildRegistrationLink(registrationHash),
  }));
  jsonExport(
    hcProvidersForExport,
    {
      headers: Object.keys(hcProvidersForExport[0]),
    },
    (err, csv) => {
      downloadCSV(csv, ResourceLabels[Resources.HC_PROVIDERS]);
    },
  );
};

const hcProviderFilters = [
  <TextInput label={hcProviderFieldNames.id.admin} source={hcProviderFieldNames.id.db} alwaysOn />,
  <TextInput label={hcProviderFieldNames.name.admin} source={hcProviderFieldNames.name.db} alwaysOn />,
];

export const HCProviderList = props => {
  const classes = useStyles();

  useListOptions(HCProviderContext, props);
  return (
    <List
      {...props}
      exporter={exporter}
      filters={hcProviderFilters}
      title={<Header resource={ResourceLabels[Resources.HC_PROVIDERS]} />}
      bulkActionButtons={<DeleteManyDialog {...deleteManyDialogOptions[Resources.HC_PROVIDERS]} />}
      perPage={RECORDS_PER_PAGE}
    >
      <Datagrid rowClick="show" className={classes.overflowColumn}>
        <TextField label={hcProviderFieldNames.id.admin} source={hcProviderFieldNames.id.db} />
        <TextField label={hcProviderFieldNames.name.admin} source={hcProviderFieldNames.name.db} />
        <RegistrationLinkField hashFieldName={hcProviderFieldNames.registrationLink.db} />
      </Datagrid>
    </List>
  );
};
