import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation invitePatientByAdmin($patient: PatientCreateByAdmin!) {
    data: invitePatientByAdmin(patient: $patient) {
      id
      message
    }
  }
`;

export const GET_ONE = gql`
  query patient($id: Int!) {
    data: patient(id: $id) {
      id
      providerId
      currentPackageSequenceId
      lastLoginDate
      clinicians {
        profile {
          name
        }
        HCProviders {
          name
        }
      }
      profile {
        name
      }
      coins
      dailyStreak
      currentPackageSequenceId
      walletId
    }
  }
`;

export const GET_LIST = gql`
  query patients($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: patients(order: $order, limit: $limit, offset: $offset, where: $where) {
      id
      providerId
      name
      currentPackageSequenceId
      reviewsCount
      momentsCount
    }
    total: patients(where: $where) {
      id
    }
  }
`;

export const GET_MANY = GET_LIST;

export const UPDATE = gql`
  mutation updatePatient($patient: PatientUpdate) {
    data: updatePatient(patient: $patient) {
      id
      providerId
      lastLoginDate
      clinicians {
        profile {
          name
        }
        HCProviders {
          name
        }
      }
      profile {
        name
      }
      coins
      dailyStreak
      currentPackageSequenceId
      currentPackageNumber
      momentsCount
      reviewsCount
    }
  }
`;

export const DELETE = gql`
  mutation destroyPatient($patient: PatientDestroy) {
    data: destroyPatient(patient: $patient) {
      id
    }
  }
`;

export const DELETE_MANY = gql`
  mutation bulkDestroyPatients($patients: PatientsBulkDestroy) {
    data: bulkDestroyPatients(patients: $patients)
  }
`;

export const UPDATE_MANY = gql`
  mutation assignPackageSequence($packageSequenceId: Int, $ids: [Int], $isExclude: Boolean) {
    data: assignPackageSequence(packageSequenceId: $packageSequenceId, ids: $ids, isExclude: $isExclude)
  }
`;
