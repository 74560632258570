import {
  ArrayField,
  Datagrid,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

import { deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { Header } from 'components/Header';
import { BaseActionsToolbar } from 'components/toolbars/BaseActionsToolbar';
import { deleteOneDialogOptions } from 'constants/dialogOptions';
import { patientFields } from 'resources/patient/types';
import { Resources } from 'types';

import { clinicianFieldNames } from './types';

const useStyles = makeStyles({
  layout: {
    maxWidth: '80%',
    '& .MuiFormControl-root > div': {
      padding: '2px 0 4px',

      '& > .MuiTypography-root': {
        fontSize: '1rem',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: deepPurple.A400,
      height: 3,
    },
    '& .MuiDivider-root': {
      display: 'none',
    },
  },
  longString: {
    overflowWrap: 'anywhere',
  },
});

export const ClinicianShow = props => {
  const classes = useStyles();
  const { record, resource } = useShowController(props);

  return (
    <Show {...props} actions={false} title={<Header resource={resource} id={record?.profile?.name} />}>
      <SimpleShowLayout className={classes.layout}>
        <TextField
          label={clinicianFieldNames.id.admin}
          source={clinicianFieldNames.id.db}
          className={classes.longString}
        />
        <TextField
          label={clinicianFieldNames.fullName.admin}
          source={clinicianFieldNames.fullName.db}
          className={classes.longString}
        />
        <TextField
          label={clinicianFieldNames.providerId.admin}
          source={clinicianFieldNames.providerId.db}
          className={classes.longString}
        />
        <TextField label={clinicianFieldNames.organization.admin} source={clinicianFieldNames.organization.db} />
        <ArrayField label={'Linked patients'} source={'patients'}>
          <Datagrid>
            <ReferenceField
              label={'Patient ID'}
              source={patientFields.id.db}
              reference={Resources.PATIENTS}
              link="show"
            >
              <NumberField source={patientFields.id.db} />
            </ReferenceField>
            <TextField
              label={patientFields.patientName.admin}
              source={patientFields.patientName.db}
              className={classes.longString}
            />
            <TextField
              label={patientFields.email.admin}
              source={patientFields.email.db}
              className={classes.longString}
            />
          </Datagrid>
        </ArrayField>
        <BaseActionsToolbar isEditable={true} deleteActionProps={deleteOneDialogOptions[Resources.CLINICIANS]} />
      </SimpleShowLayout>
    </Show>
  );
};
