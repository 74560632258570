import {
  Button,
  Datagrid,
  DateField,
  downloadCSV,
  FunctionField,
  List,
  Record,
  TextField,
  TextInput,
  useRedirect,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import jsonExport from 'jsonexport/dist';

import { Header } from 'components/Header';
import { RECORDS_PER_PAGE } from 'constants/common';
import { THREAD_MODERATOR_INVITES_ROUTE } from 'constants/routes';
import { ThreadModeratorContext } from 'context/threadModerator';
import { useListOptions } from 'hooks/useListOptions';
import { ResourceLabels, Resources } from 'types';
import { DATE_FORMAT } from 'utils/const';

import { threadModeratorFields } from './types';

const exporter = threadModerator => {
  const threadModeratorsForExport = threadModerator.map(fields => ({
    [threadModeratorFields.id.admin]: fields[threadModeratorFields.id.db],
    [threadModeratorFields.email.admin]: fields[threadModeratorFields.email.db],
    [threadModeratorFields.firstName.admin]: fields[threadModeratorFields.firstName.db],
    [threadModeratorFields.lastName.admin]: fields[threadModeratorFields.lastName.db],
    [threadModeratorFields.credentials.admin]: fields[threadModeratorFields.credentials.db],
    [threadModeratorFields.updatedAt.admin]: dayjs(fields[threadModeratorFields.updatedAt.db]).format(DATE_FORMAT),
  }));
  jsonExport(
    threadModeratorsForExport,
    {
      headers: [
        threadModeratorFields.id.admin,
        threadModeratorFields.email.admin,
        threadModeratorFields.firstName.admin,
        threadModeratorFields.lastName.admin,
        threadModeratorFields.credentials.admin,
        threadModeratorFields.updatedAt.admin,
      ],
    },
    (err, csv) => {
      downloadCSV(csv, ResourceLabels[Resources.THREAD_MODERATORS]);
    },
  );
};

const threadModeratorFilters = [
  <TextInput label={threadModeratorFields.id.admin} source={threadModeratorFields.id.db} alwaysOn />,
  <TextInput label={threadModeratorFields.email.admin} source={threadModeratorFields.email.db} alwaysOn />,
  <TextInput label={threadModeratorFields.firstName.admin} source={threadModeratorFields.firstName.db} alwaysOn />,
  <TextInput label={threadModeratorFields.lastName.admin} source={threadModeratorFields.lastName.db} alwaysOn />,
];

const useStyles = makeStyles(theme => ({
  overflow: {
    '& .column-action': { overflowWrap: 'anywhere' },
  },
  button: {
    alignSelf: 'flex-start',
    margin: '20px 0',
    backgroundColor: theme.palette.info.main,
  },
}));

export const ThreadModeratorList = props => {
  const classes = useStyles();
  useListOptions(ThreadModeratorContext, props);
  const redirect = useRedirect();

  return (
    <>
      <Button
        label="Show invites"
        onClick={() => redirect(THREAD_MODERATOR_INVITES_ROUTE)}
        variant="contained"
        submitOnEnter={false}
        className={classes.button}
      />

      <List
        {...props}
        exporter={exporter}
        filters={threadModeratorFilters}
        title={<Header resource={ResourceLabels[Resources.THREAD_MODERATORS]} />}
        bulkActionButtons={false}
        perPage={RECORDS_PER_PAGE}
      >
        <Datagrid rowClick="show" className={classes.overflow}>
          <TextField label={threadModeratorFields.id.admin} source={threadModeratorFields.id.db} sortable={false} />
          <TextField label={threadModeratorFields.email.admin} source={threadModeratorFields.email.db} />
          <TextField label={threadModeratorFields.firstName.admin} source={threadModeratorFields.firstName.db} />
          <TextField label={threadModeratorFields.lastName.admin} source={threadModeratorFields.lastName.db} />
          <FunctionField
            label={threadModeratorFields.credentials.admin}
            render={(record?: Record) => record?.[threadModeratorFields.credentials.db] || '--'}
          />
          <DateField
            label={threadModeratorFields.updatedAt.admin}
            source={threadModeratorFields.updatedAt.db}
            options={{ month: '2-digit', day: 'numeric', year: 'numeric' }}
            locales="en-EN"
          />
        </Datagrid>
      </List>
    </>
  );
};
