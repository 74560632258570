import { FC, useEffect, useState } from 'react';
import { Admin, LegacyDataProvider, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { ResourceWithContext } from 'components/context/ResourceWithContext';
import { Layout } from 'components/layout/index';
import { THREAD_MODERATOR_INVITES_ROUTE } from 'constants/routes';
import { ClinicianContextProvider } from 'context/clinician';
import { HCProviderContextProvider } from 'context/hcProvider';
import { LoticUserContextProvider } from 'context/loticUser';
import { PackageSequenceContextProvider } from 'context/packageSequence';
import { PatientContextProvider } from 'context/patient';
import { ThreadModeratorContextProvider } from 'context/threadModerator';
import { client, webClient, webClientResources } from 'data/provider';
import { buildProvider } from 'data/provider';
import { ClinicianCreate, ClinicianEdit, ClinicianList, ClinicianShow } from 'resources/clinician';
import { HCProviderCreate, HCProviderEdit, HCProviderList, HCProviderShow } from 'resources/hcProvider';
import { LoticUserList } from 'resources/loticUser';
import { MarketingWebsitesUserList } from 'resources/marketingWebsitesUser';
import { OnboardingSurveySubmissionList } from 'resources/onboardingSurvey/list';
import { PatientCreate, PatientEdit, PatientList, PatientShow } from 'resources/patient';
import { ThreadModeratorList } from 'resources/threadModerator';
import { ThreadModeratorInviteCreate, ThreadModeratorInvitePage } from 'resources/threadModeratorInvite';
import { ResourceLabels, Resources } from 'types';

export const ReactAdmin: FC = () => {
  const [provider, setProvider] = useState<LegacyDataProvider | null>(null);
  const [webProvider, setWebProvider] = useState<LegacyDataProvider | null>(null);

  useEffect(() => {
    buildProvider(client).then(result => setProvider(() => result));
    buildProvider(webClient).then(result => setWebProvider(() => result));
  }, []);

  if (!provider || !webProvider) {
    return <LinearProgress className="progress-loader" />;
  }

  const combineProviders = (type, resource, params): Promise<LegacyDataProvider> => {
    if (webClientResources.includes(resource)) {
      return webProvider(type, resource, params);
    }

    return provider(type, resource, params);
  };

  return (
    <Admin
      dataProvider={combineProviders}
      layout={Layout}
      customRoutes={[<Route exact path={THREAD_MODERATOR_INVITES_ROUTE} component={ThreadModeratorInvitePage} />]}
    >
      <ResourceWithContext
        name={Resources.HC_PROVIDERS}
        options={{ label: ResourceLabels[Resources.HC_PROVIDERS] }}
        contextProvider={HCProviderContextProvider}
        list={HCProviderList}
        show={HCProviderShow}
        edit={HCProviderEdit}
        create={HCProviderCreate}
      />
      <ResourceWithContext
        name={Resources.PATIENTS}
        contextProvider={PatientContextProvider}
        list={PatientList}
        show={PatientShow}
        create={PatientCreate}
        edit={PatientEdit}
      />
      <ResourceWithContext
        contextProvider={ClinicianContextProvider}
        name={Resources.CLINICIANS}
        show={ClinicianShow}
        list={ClinicianList}
        edit={ClinicianEdit}
        create={ClinicianCreate}
      />
      <Resource
        name={Resources.MARKETING_WEBSITE_USERS}
        options={{ label: ResourceLabels[Resources.MARKETING_WEBSITE_USERS] }}
        list={MarketingWebsitesUserList}
      />
      <Resource
        name={Resources.ONBOARDING_SURVEYS}
        options={{ label: ResourceLabels[Resources.ONBOARDING_SURVEYS] }}
        list={OnboardingSurveySubmissionList}
      />
      <ResourceWithContext
        name={Resources.PACKAGE_SEQUENCES}
        options={{ label: ResourceLabels[Resources.PACKAGE_SEQUENCES] }}
        contextProvider={PackageSequenceContextProvider}
      />
      <ResourceWithContext
        name={Resources.LOTIC_USERS}
        contextProvider={LoticUserContextProvider}
        list={LoticUserList}
      />
      <ResourceWithContext
        name={Resources.THREAD_MODERATORS}
        contextProvider={ThreadModeratorContextProvider}
        list={ThreadModeratorList}
        create={ThreadModeratorInviteCreate}
      />
      <Resource
        name={Resources.THREAD_MODERATOR_INVITES}
        options={{ label: ResourceLabels[Resources.THREAD_MODERATOR_INVITES] }}
      />
    </Admin>
  );
};
