import { FC } from 'react';
import { LayoutProps, Layout as LO } from 'react-admin';

import { AppBar } from './AppBar';
import SectionedMenu from './SectionedMenu';
import Sidebar from './Sidebar';

export const Layout: FC<LayoutProps> = props => (
  <LO {...props} menu={SectionedMenu} sidebar={Sidebar} appBar={AppBar} />
);
