import { useState } from 'react';
import {
  AutocompleteInput,
  Button,
  DateField,
  FunctionField,
  NumberField,
  Record,
  ReferenceInput,
  SaveButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  useShowController,
} from 'react-admin';

import { useQuery } from '@apollo/client';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import KeyIcon from '@material-ui/icons/VpnKey';
import classNames from 'classnames';

import UpdateActionDialog from 'components/dialogs/UpdateActionDialog';
import PlainTextField from 'components/fields/PainTextField';
import { Header } from 'components/Header';
import { BaseActionsToolbar } from 'components/toolbars/BaseActionsToolbar';
import { deleteOneDialogOptions } from 'constants/dialogOptions';
import { client } from 'data/provider';
import { MOMENT_SUBMISSIONS_COUNT } from 'data/queries/momentSubmissions';
import { REVIEWS_SUBMISSIONS_COUNT } from 'data/queries/reviewSubmissions';
import { PackageSequence } from 'resources/packageSequence/types';
import { Resources } from 'types';
import { renderOptionText } from 'utils';
import { buildPublishedAtFilter } from 'utils/filters';

import { UserActivitiesTables } from './components';
import { patientFields } from './types';

const useStyles = makeStyles({
  layout: {
    maxWidth: '80%',
    '& .MuiFormControl-root > div': {
      padding: '2px 0 4px',

      '& > .MuiTypography-root': {
        fontSize: '1rem',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: deepPurple.A400,
      height: 3,
    },
    '& .MuiDivider-root': {
      display: 'none',
    },
  },
  subheader: {
    borderBottom: '2px solid #9eadba',
    margin: '15px 0 5px',
  },
  commonBtn: {
    backgroundColor: deepPurple.A400,
    border: 'none',
    borderRadius: '4px',
    display: 'inline-flex',

    '&:hover': {
      backgroundColor: fade(deepPurple.A400, 0.7),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  publishSequenceBtn: {
    textTransform: 'none',
    marginLeft: 5,
  },
  sequenceForm: {
    '& form': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiCardContent-root': {
      padding: 0,
      flexGrow: 1,
    },
    '& .MuiToolbar-root': {
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      minHeight: 'auto',
    },
    '& .MuiFormHelperText-root, .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  longString: {
    overflowWrap: 'anywhere',
  },
});

export const PatientShow = props => {
  const classes = useStyles();
  const { record, resource } = useShowController(props);

  const [openPublishSequenceDialog, setOpenPublishSequenceDialog] = useState<boolean>(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState<boolean>(false);
  const [currentPackageSequenceId, setCurrentPackageSequenceId] = useState<number>();

  const submitHandler = () => setOpenPublishSequenceDialog(true);
  const openResetPasswordDialogHandler = () => setOpenResetPasswordDialog(true);
  const closeResetPasswordDialogHandler = () => setOpenResetPasswordDialog(false);
  const closePublishSequenceDialogHandler = () => setOpenPublishSequenceDialog(false);

  const { data: momentsTotal, loading: momentsLoading } = useQuery(MOMENT_SUBMISSIONS_COUNT, {
    client,
    variables: { where: { patientId: record?.id } },
  });

  const { data: reviewsTotal, loading: reviewsLoading } = useQuery(REVIEWS_SUBMISSIONS_COUNT, {
    client,
    variables: { where: { patientId: record?.id } },
  });

  return (
    <Show {...props} actions={false} title={<Header resource={resource} id={record?.profile?.name} />}>
      <SimpleShowLayout className={classes.layout}>
        <TextField label={patientFields.id.admin} source={patientFields.id.db} className={classes.longString} />
        <TextField
          label={patientFields.patientName.admin}
          source={patientFields.patientName.db}
          className={classes.longString}
        />
        <TextField label={patientFields.email.admin} source={patientFields.email.db} className={classes.longString} />
        <SimpleForm
          save={submitHandler}
          className={classes.sequenceForm}
          toolbar={
            <Toolbar>
              <SaveButton
                disabled={!currentPackageSequenceId}
                label="Publish"
                variant="contained"
                submitOnEnter={false}
                className={classNames(classes.commonBtn, classes.publishSequenceBtn)}
              />
            </Toolbar>
          }
        >
          <ReferenceInput
            label={patientFields.sequence.admin}
            onChange={(value: number) => setCurrentPackageSequenceId(value)}
            source={patientFields.sequence.db}
            reference={Resources.PACKAGE_SEQUENCES}
            filterToQuery={(searchText: string) => ({
              title: searchText,
            })}
            filter={buildPublishedAtFilter(true)}
            sort={{ field: 'id', order: 'ASC' }}
            fullWidth
          >
            <AutocompleteInput optionText={({ uid, title }: PackageSequence) => renderOptionText(uid, title)} />
          </ReferenceInput>
        </SimpleForm>
        <PlainTextField classes={{ root: classes.subheader }} variant="h5">
          Account Info
        </PlainTextField>
        <FunctionField
          className={classes.longString}
          label={patientFields.hcProvider.admin}
          render={(record?: Record) =>
            record?.clinicians
              ?.map(clinician => clinician?.HCProviders?.map(HCProvider => HCProvider?.name).join(', '))
              .join(', ')
          }
        />
        <FunctionField
          className={classes.longString}
          label={patientFields.clinician.admin}
          render={(record?: Record) => record?.clinicians?.map(clinician => clinician?.profile?.name).join(', ')}
        />
        <TextField label="Reset Password" />
        <Button
          startIcon={<KeyIcon />}
          label="RESET PASSWORD"
          onClick={openResetPasswordDialogHandler}
          variant="contained"
          submitOnEnter={false}
          className={classes.commonBtn}
        />
        <TextField
          label={patientFields.walletId.admin}
          source={patientFields.walletId.db}
          className={classes.longString}
        />
        <PlainTextField classes={{ root: classes.subheader }} variant="h5">
          User Activity
        </PlainTextField>
        <DateField
          label={patientFields.lastLoginDate.admin}
          source={patientFields.lastLoginDate.db}
          locales="en-EN"
          showTime
        />
        <FunctionField
          label={patientFields.reviews.admin}
          render={() => (reviewsLoading ? <div>Loading..</div> : reviewsTotal?.total ?? <div>Load failed</div>)}
        />
        <FunctionField
          label={patientFields.moments.admin}
          render={() => (momentsLoading ? <div>Loading...</div> : momentsTotal?.total ?? <div>Load failed</div>)}
        />
        <NumberField label={patientFields.coins.admin} source={patientFields.coins.db} />
        <NumberField label={patientFields.dailyStreak.admin} source={patientFields.dailyStreak.db} />

        <UserActivitiesTables patientId={record?.id as number} />

        <BaseActionsToolbar isEditable={false} deleteActionProps={deleteOneDialogOptions[Resources.PATIENTS]} />
        <UpdateActionDialog
          confirmBtnName="PUBLISH"
          title="Assign new sequence"
          content={`This will effect the user's in app\nexperience by overriding the user's\ncurrent sequence of packages.\nAre you sure you want to publish a\nnew sequence to their app?`}
          notifySuccess="Sequence Published"
          openDialog={openPublishSequenceDialog}
          recordFields={{ currentPackageSequenceId, currentPackageNumber: 1 }}
          onClose={closePublishSequenceDialogHandler}
        />
        <UpdateActionDialog
          confirmBtnName="RESET PASSWORD"
          title="Reset Password"
          content={`This will override the user's current\npassword and lock them out of their\naccount until they create a new\npassword.`}
          notifySuccess="Password Reset"
          openDialog={openResetPasswordDialog}
          recordFields={{ providerKey: null }}
          mutationMode="undoable"
          onClose={closeResetPasswordDialogHandler}
        />
      </SimpleShowLayout>
    </Show>
  );
};
