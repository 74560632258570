import _isEmail from 'validator/es/lib/isEmail';
import _isMobilePhone from 'validator/es/lib/isMobilePhone';

export const isInteger = (value: number) => {
  if (!value || value.toString().includes('.')) {
    return 'Must be integer';
  }
};

/**
 * Validation function that checks if provided value is a valid email format
 *
 * @param isRequired flag that indicates if provided value should be not empty
 * @returns callback which make validation upon provided value.
 *
 * Callback properties:
 * @param value value that should be validated;
 * if `isRequired` parameter is `true` then the empty values (`null`, `undefined`) are also considered valid
 * @returns undefined if validation is successfull; otherwise, return error message
 */
export const isEmail = (isRequired: boolean) => (value?: string) => {
  if (!isRequired && (value === null || value === undefined)) return;
  if (!value || !_isEmail(value)) {
    return 'Must be a valid email format';
  }
};

/**
 * Checks if string value fits min provided length
 *
 * @param minValue - minimum (implicitly) string length that provided value could have
 * @returns callback which make validation upon provided value.
 *
 * Callback properties:
 * @param value value that should be validated;
 * @returns undefined if validation is successful or value is empty; otherwise, return error message
 */
export const minLength =
  (minValue: number) =>
  (value?: string): string | undefined => {
    if (!value) return;

    if (value.length < minValue) {
      return `Value can't has length less than ${minValue}`;
    }
  };

/**
 * Checks if string value fits max provided length
 *
 * @param maxValue -  maximum (implicitly) string length that provided value could have
 * @returns callback which make validation upon provided value.
 *
 * Callback properties:
 * @param value value that should be validated;
 * @returns undefined if validation is successful or value is empty; otherwise, return error message
 */
export const maxLength =
  (maxValue: number) =>
  (value?: string): string | undefined => {
    if (!value) return;

    if (value.length > maxValue) {
      return `Value can't has length bigger than ${maxValue}`;
    }
  };

/**
 * Validation function that checks if provided value is a valid phone format
 *
 * @param isRequired flag that indicates if provided value should be not empty
 * @returns callback which make validation upon provided value.
 *
 * Callback properties:
 * @param value value that should be validated;
 * if `isRequired` parameter is `true` then the empty values (`null`, `undefined`) are also considered valid
 * @returns undefined if validation is successfull; otherwise, return error message
 */
export const isMobilePhone = (isRequired: boolean) => (value?: string) => {
  if (!isRequired && (value === null || value === undefined)) return;
  if (!value || !_isMobilePhone(value)) {
    return 'Must be a valid phone number format';
  }
};
