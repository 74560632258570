import { Labeled, useRecordContext } from 'react-admin';

import { buildRegistrationLink } from 'utils';

const createFieldComponent = (fieldLabel, fieldContent) =>
  fieldLabel ? <Labeled label={fieldLabel}>{fieldContent}</Labeled> : <>{fieldContent}</>;

export const RegistrationLinkField = props => {
  const { hashFieldName, label } = props;
  const record = useRecordContext();
  const registrationLink = buildRegistrationLink(record[hashFieldName]);
  const registrationLinkComponent = (
    <a href={registrationLink} target="_blank" rel="noreferrer">
      {registrationLink}
    </a>
  );

  return createFieldComponent(label, registrationLinkComponent);
};
