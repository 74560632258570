import { useState } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  Edit,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TransformData,
  useEditController,
} from 'react-admin';

import { Box } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import KeyIcon from '@material-ui/icons/VpnKey';

import ResetPasswordDialog from 'components/dialogs/ResetPasswordDialog';
import PlainTextField from 'components/fields/PainTextField';
import { BaseEditToolbar } from 'components/toolbars/BaseEditToolbar';
import { patientFields } from 'resources/patient/types';
import { Resources } from 'types';

import { clinicianFieldNames } from './types';

const useStyles = makeStyles({
  table: {
    minWidth: '50vw',
  },
  longString: {
    overflowWrap: 'anywhere',
  },
  resetCode: {
    margin: '10px 0 10px 0',
  },
  commonBtn: {
    backgroundColor: deepPurple.A400,
    border: 'none',
    borderRadius: '4px',
    display: 'inline-flex',

    '&:hover': {
      backgroundColor: fade(deepPurple.A400, 0.7),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export const ClinicianEdit = props => {
  const { record } = useEditController(props);
  const classes = useStyles();

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState<boolean>(false);

  const openResetPasswordDialogHandler = () => setOpenResetPasswordDialog(true);
  const closeResetPasswordDialogHandler = () => setOpenResetPasswordDialog(false);

  const editTransformHandler: TransformData = data => {
    const { id, providerId, profile, HCProviders } = data;
    const hcProviderId = data.hcProviderId ? data.hcProviderId : HCProviders[0].id;
    const transformed = {
      id,
      providerId,
      hcProviderId,
      profile: {
        id: profile.id,
        name: profile.name,
      },
    };
    return transformed;
  };

  return (
    <Edit {...props} transform={data => editTransformHandler(data)}>
      <SimpleForm toolbar={<BaseEditToolbar id={record?.id} />}>
        <TextField
          label={clinicianFieldNames.id.admin}
          source={clinicianFieldNames.id.db}
          className={classes.longString}
        />
        <TextInput
          label={clinicianFieldNames.fullName.admin}
          source={clinicianFieldNames.fullName.db}
          className={classes.longString}
        />
        <TextInput
          llabel={clinicianFieldNames.providerId.admin}
          source={clinicianFieldNames.providerId.db}
          className={classes.longString}
        />
        <ReferenceInput
          label={clinicianFieldNames.organization.admin}
          source={clinicianFieldNames.organization.source}
          reference={Resources.HC_PROVIDERS}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextField label="Reset Password" />
        <Button
          startIcon={<KeyIcon />}
          label="RESET PASSWORD"
          onClick={openResetPasswordDialogHandler}
          variant="contained"
          submitOnEnter={false}
          className={classes.commonBtn}
        />
        {record?.resetPasswordCode && (
          <>
            <PlainTextField variant="body2" classes={{ root: classes.resetCode }}>
              {'Reset Code Sent!'}
            </PlainTextField>
          </>
        )}
        <DateField
          label={clinicianFieldNames.resetPassExpiry.admin}
          source={clinicianFieldNames.resetPassExpiry.db}
          showTime={true}
          locales="en-EN"
        />

        <ArrayField label={'Linked patients'} source={'patients'}>
          <Box>
            <Datagrid className={classes.table}>
              <ReferenceField
                label={'Patient ID'}
                source={patientFields.id.db}
                reference={Resources.PATIENTS}
                link="show"
              >
                <NumberField source={patientFields.id.db} />
              </ReferenceField>
              <TextField
                label={patientFields.patientName.admin}
                source={patientFields.patientName.db}
                className={classes.longString}
              />
              <TextField
                label={patientFields.email.admin}
                source={patientFields.email.db}
                className={classes.longString}
              />
            </Datagrid>
          </Box>
        </ArrayField>

        <ResetPasswordDialog
          confirmBtnName="RESET PASSWORD"
          title="Reset Password"
          content={`This will override the user's current\npassword and lock them out of their\naccount until they create a new\npassword.`}
          openDialog={openResetPasswordDialog}
          onClose={closeResetPasswordDialogHandler}
        />
      </SimpleForm>
    </Edit>
  );
};
