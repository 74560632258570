import { FC, RefAttributes, useState } from 'react';

import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { DataGrid, DataGridProps, GridColDef, GridSortModel } from '@mui/x-data-grid';

import { RECORDS_PER_PAGE } from 'constants/common';
import { client } from 'data/provider';

type IDataGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'page'
  | 'paginationMode'
  | 'rowCount'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'onPageChange'
>;

interface Props {
  dataQuery: Query;
  totalQuery: Query;
  columns: GridColDef[];
  dataGridProps?: IDataGridProps & RefAttributes<HTMLDivElement>;
  perPage?: number;
}

interface Query {
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>;
  options?: QueryHookOptions<any, OperationVariables> | undefined;
}

export const DataTable: FC<Props> = ({ dataQuery, totalQuery, columns, dataGridProps, perPage }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(perPage ?? RECORDS_PER_PAGE);
  const [sortModel, setSortModel] = useState<GridSortModel>([...(dataGridProps?.sortModel || [])]);

  const { data: totalData } = useQuery(totalQuery.query, { client, ...totalQuery.options });

  const { data, loading } = useQuery(dataQuery.query, {
    client,
    ...dataQuery.options,
    variables: {
      ...dataQuery.options?.variables,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ...(sortModel[0]?.field && { order: parseOrder(sortModel) }),
    },
  });

  return (
    <div>
      <DataGrid
        // not overrideable
        rows={data?.data || []}
        columns={columns}
        loading={loading}
        page={page}
        paginationMode="server"
        rowCount={totalData?.total || 0}
        pageSize={rowsPerPage}
        onPageSizeChange={value => setRowsPerPage(value)}
        onPageChange={value => setPage(value)}
        // overrideable
        autoHeight
        rowsPerPageOptions={[5, 10, 25, 50]}
        onSortModelChange={order => setSortModel(order)}
        getRowHeight={() => 'auto'}
        isRowSelectable={() => false}
        {...dataGridProps}
        sortModel={sortModel}
      />
    </div>
  );
};

const parseOrder = (order: GridSortModel) => {
  if (order[0]?.sort === 'desc') {
    return `reverse:${order[0].field}`;
  } else {
    return order[0]?.field;
  }
};
