import { gql } from '@apollo/client';

export const REVIEW_SUBMISSIONS = gql`
  query reviewSubmissions($limit: Int, $offset: Int, $order: String, $where: JSON) {
    data: reviewSubmissions(where: $where, limit: $limit, offset: $offset, order: $order) {
      id
      createdAt
      review {
        title
        uid
      }
      reviewSubmissionAnswers {
        answer
        question {
          uid
        }
      }
    }
  }
`;

export const REVIEWS_SUBMISSIONS_COUNT = gql`
  query reviewsCount($where: JSON) {
    total: reviewsCount(where: $where)
  }
`;
