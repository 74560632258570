//@ts-ignore
import { ApolloClient, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import buildGraphQLProvider from 'ra-data-graphql';

import { Resources } from 'types';
import { Entities } from 'types/entities';
import config from 'utils/config';
import { REQUEST_TIMEOUT, TOKEN_KEY } from 'utils/const';

import queries from './queries';

import { RECORDS_PER_PAGE } from '../constants/common';

// NOTE: This function simply adds the `s` letter at the end of passed resource name,
// so if there are resources with a non-simple plural form of their name (e.g. `-es`, `-is`, etc.),
// then this function should be refactored
/**
 * Pluralizes passed resource name
 *
 * @param resourceName string to pluralize
 * @returns pluralized `resourceName`
 */
const pluralizeResourceName = (resourceName: string) => `${resourceName}s`;

export const buildQuery = introspectionResults => (fetchType, resourceName: string, params) => {
  let variables = {};
  if (fetchType === 'GET_LIST') {
    const order = `${params?.sort?.order === 'DESC' ? 'reverse:' : ''}${params?.sort?.field || 'id'}`;
    const limit = params?.pagination?.perPage || RECORDS_PER_PAGE;
    const page = params?.pagination?.page || 1;
    const offset = limit * page - limit;
    const where = Object.keys(params?.filter).length ? { filter: params.filter } : {};

    variables = {
      where,
      order,
      limit,
      offset,
    };
  } else if (fetchType === 'GET_ONE') {
    variables = {
      ...params,
      id: parseInt(params.id),
    };
  } else if (['CREATE', 'UPDATE'].includes(fetchType)) {
    variables = {
      [Entities[resourceName]]: params?.data,
    };
  } else if (fetchType === 'DELETE') {
    variables = {
      [Entities[resourceName]]: {
        id: parseInt(params.id),
      },
    };
  } else if (fetchType === 'DELETE_MANY') {
    const pluralizedResourceName = pluralizeResourceName(Entities[resourceName]);
    variables = {
      [pluralizedResourceName]: params,
    };
  } else if (['UPDATE_MANY'].includes(fetchType)) {
    variables = params;
  } else if (fetchType === 'GET_MANY') {
    variables = {
      where: {
        filter: {
          recordsIds: params?.ids || [],
        },
      },
    };
  }

  const query = queries?.[resourceName]?.[fetchType];
  if (!query) {
    throw new Error(`Missing Query for ${resourceName}:${fetchType}`);
  }
  const r = {
    query,
    variables,
    parseResponse: response => {
      let reply = { ...response.data };
      if (reply?.total) {
        reply.total = reply.total?.length;
      }
      return reply;
    },
  };
  return r;
};

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem(TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const timeoutLink = new ApolloLinkTimeout(REQUEST_TIMEOUT);

export const client = new ApolloClient({
  link: from([
    authLink,
    timeoutLink,
    createUploadLink({
      uri: config.api.graphQLEndpoint,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
});

export const webClient = new ApolloClient({
  link: from([
    authLink,
    timeoutLink,
    createUploadLink({
      uri: config.api.webQraphQLEndpoint,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
});

export const buildProvider = async (client: ApolloClient<NormalizedCacheObject>) =>
  buildGraphQLProvider({
    client,
    buildQuery,
  });

export const webClientResources = [Resources.MARKETING_WEBSITE_USERS];
