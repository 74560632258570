import { FC } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import { usePromtLogin } from 'hooks/useGoogleAuth';

export const Auth: FC = () => {
  const signIn = usePromtLogin();

  return (
    <div className="auth-container">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className="sign-in-block">
          <Typography className="auth-title" variant="h5">
            Sign in
          </Typography>
          <Box className="actions-block">
            <Button onClick={signIn} fullWidth size="large" variant="contained">
              Sign In with Google
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
