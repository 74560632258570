import { NotificationType, useNotify } from 'react-admin';

import { MAX_ERROR_LENGTH } from 'constants/errorLength';
import { errorMessages, errorPrefix, tooLongErrorPostfix } from 'constants/errorMessages';

export const useError = () => {
  const notify = useNotify();
  return (
    message: string,
    type: NotificationType = 'info',
    messageArgs: any = {},
    undoable: boolean = false,
    autoHideDuration?: number,
  ) => {
    const modifiedMessage = getModifiedMessage(message);
    return notify(modifiedMessage, type, messageArgs, undoable, autoHideDuration);
  };
};

const getModifiedMessage = (message: string): string => {
  const foundMessage = findMessageByKey(message);
  return errorPrefix + (foundMessage ?? modifyLongError(message));
};

const findMessageByKey = (message: string): string => {
  const key = Object.keys(errorMessages).find(key => message.startsWith(key));
  return key ? errorMessages[key] : undefined;
};

const modifyLongError = (message: string): string => {
  if (message.length > MAX_ERROR_LENGTH) {
    return message.substring(0, MAX_ERROR_LENGTH) + tooLongErrorPostfix;
  }
  return message;
};
