import { Datagrid, DateField, downloadCSV, List, SelectInput, TextField, TextInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import jsonExport from 'jsonexport/dist';

import { Header } from 'components/Header';
import { RECORDS_PER_PAGE } from 'constants/common';
import { ResourceLabels, Resources } from 'types';
import { DATE_FORMAT } from 'utils/const';

import { ThreadInviteStatus, threadModeratorInviteFields } from './types';

const exporter = threadModeratorInvite => {
  const threadModeratorInvitesForExport = threadModeratorInvite.map(fields => ({
    [threadModeratorInviteFields.id.admin]: fields[threadModeratorInviteFields.id.db],
    [threadModeratorInviteFields.email.admin]: fields[threadModeratorInviteFields.email.db],
    [threadModeratorInviteFields.status.admin]: fields[threadModeratorInviteFields.status.db],
    [threadModeratorInviteFields.expiredAt.admin]: dayjs(fields[threadModeratorInviteFields.expiredAt.db]).format(
      DATE_FORMAT,
    ),
    [threadModeratorInviteFields.updatedAt.admin]: dayjs(fields[threadModeratorInviteFields.updatedAt.db]).format(
      DATE_FORMAT,
    ),
  }));
  jsonExport(
    threadModeratorInvitesForExport,
    {
      headers: [
        threadModeratorInviteFields.id.admin,
        threadModeratorInviteFields.email.admin,
        threadModeratorInviteFields.status.admin,
        threadModeratorInviteFields.expiredAt.admin,
        threadModeratorInviteFields.updatedAt.admin,
      ],
    },
    (err, csv) => {
      downloadCSV(csv, ResourceLabels[Resources.THREAD_MODERATOR_INVITES]);
    },
  );
};

const threadModeratorInviteFilters = [
  <TextInput label={threadModeratorInviteFields.id.admin} source={threadModeratorInviteFields.id.db} alwaysOn />,
  <TextInput label={threadModeratorInviteFields.email.admin} source={threadModeratorInviteFields.email.db} alwaysOn />,
  <TextInput label={threadModeratorInviteFields.name.admin} source={threadModeratorInviteFields.name.db} alwaysOn />,
  <SelectInput
    label={threadModeratorInviteFields.status.admin}
    source={threadModeratorInviteFields.status.db}
    choices={Object.values(ThreadInviteStatus).map(name => ({ id: name, name }))}
    alwaysOn
  />,
];

const useStyles = makeStyles({
  overflow: {
    '& .column-action': { overflowWrap: 'anywhere' },
  },
});

export const ThreadModeratorInviteList = props => {
  const classes = useStyles();

  return (
    <List
      {...props}
      exporter={exporter}
      filters={threadModeratorInviteFilters}
      resource={Resources.THREAD_MODERATOR_INVITES}
      title={<Header resource={ResourceLabels[Resources.THREAD_MODERATOR_INVITES]} />}
      bulkActionButtons={false}
      perPage={RECORDS_PER_PAGE}
    >
      <Datagrid rowClick="show" className={classes.overflow}>
        <TextField
          label={threadModeratorInviteFields.id.admin}
          source={threadModeratorInviteFields.id.db}
          sortable={false}
        />
        <TextField label={threadModeratorInviteFields.email.admin} source={threadModeratorInviteFields.email.db} />
        <TextField label={threadModeratorInviteFields.name.admin} source={threadModeratorInviteFields.name.db} />
        <TextField label={threadModeratorInviteFields.status.admin} source={threadModeratorInviteFields.status.db} />
        <DateField
          label={threadModeratorInviteFields.expiredAt.admin}
          source={threadModeratorInviteFields.expiredAt.db}
          options={{ month: '2-digit', day: 'numeric', year: 'numeric' }}
          locales="en-EN"
        />
        <DateField
          label={threadModeratorInviteFields.updatedAt.admin}
          source={threadModeratorInviteFields.updatedAt.db}
          options={{ month: '2-digit', day: 'numeric', year: 'numeric' }}
          locales="en-EN"
        />
      </Datagrid>
    </List>
  );
};
