import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query loticUsers($order: String, $offset: Int, $limit: Int, $where: JSON) {
    data: loticUsers(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      providerId
      role
      profile {
        name
        title
      }
    }
    total: loticUsers(where: $where) {
      id
    }
  }
`;
export const GET_MANY = GET_LIST;
