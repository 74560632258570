import { FC } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import _get from 'lodash/get';

import { DataTable } from 'components/DataTable/DataTable';
import { REVIEW_SUBMISSIONS, REVIEWS_SUBMISSIONS_COUNT } from 'data/queries/reviewSubmissions';
import { LONG_REQUEST_TIMEOUT } from 'utils/const';

import { patientFields } from '../types';

interface Props {
  patientId: number;
}

const columns: GridColDef[] = [
  {
    headerName: patientFields.review.id.admin,
    field: patientFields.reviewSubmission.id.db,
    sortable: false,
    filterable: false,
    valueGetter: params => _get(params.row, patientFields.reviewSubmission.id.db),
  },
  {
    headerName: patientFields.review.createdAt.admin,
    field: patientFields.review.createdAt.db,
    filterable: false,
    valueFormatter: params => new Date(params.value).toLocaleString('en-US'),
  },
  {
    headerName: patientFields.review.title.admin,
    field: patientFields.reviewSubmission.title.db,
    sortable: false,
    filterable: false,
    valueGetter: params => _get(params.row, patientFields.reviewSubmission.title.db),
    width: 250,
  },
  {
    headerName: patientFields.review.questions.admin,
    field: 'questions',
    sortable: false,
    filterable: false,
    cellClassName: 'block',
    renderCell: params =>
      _get(params.row, patientFields.reviewSubmission.questions.db).map(submission => (
        <div key={submission.question.uid}>{submission.question.uid}</div>
      )),
  },
  {
    headerName: patientFields.review.answers.admin,
    field: 'answers',
    cellClassName: 'block',
    sortable: false,
    filterable: false,
    renderCell: params =>
      _get(params.row, patientFields.reviewSubmission.answers.db)?.map(submission => (
        <div key={submission.question.uid}>{submission.answer}</div>
      )),
  },
];

export const ReviewsTable: FC<Props> = ({ patientId }) => (
  <DataTable
    dataQuery={{
      query: REVIEW_SUBMISSIONS,
      options: {
        variables: { where: { patientId } },
        context: { timeout: LONG_REQUEST_TIMEOUT },
      },
    }}
    totalQuery={{
      query: REVIEWS_SUBMISSIONS_COUNT,
      options: { variables: { where: { patientId } } },
    }}
    columns={columns}
    perPage={5}
    dataGridProps={{ sortModel: [{ field: 'createdAt', sort: 'desc' }] }}
  />
);
