import { FC } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { DataTable } from 'components/DataTable/DataTable';
import { CHECKIN_SUBMISSIONS, CHECKIN_SUBMISSIONS_COUNT } from 'data/queries/checkInSubmissions';
import { LONG_REQUEST_TIMEOUT } from 'utils/const';

import { patientFields } from '../types';

interface Props {
  patientId: number;
}

const columns: GridColDef[] = [
  {
    headerName: patientFields.checkInStatistics.createdAt.admin,
    field: patientFields.checkInStatistics.createdAt.db,
    valueFormatter: ({ value }) => new Date(value).toDateString(),
    filterable: false,
    width: 150,
  },
];

const getFilledColumns = () => {
  const additionalColumns: GridColDef[] = Object.values(patientFields.checkInsPerDay).map((parameter, index) => {
    const column: GridColDef = {
      headerName: parameter.admin,
      field: parameter.admin + index,
      valueGetter: ({ row }) => {
        return row.checkInSubmissions.find(checkIn => checkIn.checkInId === index + 1)?.answer || '--';
      },
      sortable: false,
      filterable: false,
    };

    return column;
  });

  return [...columns, ...additionalColumns];
};

export const CheckInsTable: FC<Props> = ({ patientId }) => (
  <DataTable
    dataQuery={{
      query: CHECKIN_SUBMISSIONS,
      options: {
        variables: { where: { patientId } },
        context: { timeout: LONG_REQUEST_TIMEOUT },
      },
    }}
    totalQuery={{
      query: CHECKIN_SUBMISSIONS_COUNT,
      options: { variables: { where: { patientId } } },
    }}
    dataGridProps={{ sortModel: [{ field: 'date', sort: 'desc' }] }}
    columns={getFilledColumns()}
    perPage={10}
  />
);
