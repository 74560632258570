import { FC } from 'react';
import { Children } from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';

interface SimpleDatagridHeaderProps {
  children?: JSX.Element[];
}

const SimpleDatagridHeader: FC<SimpleDatagridHeaderProps> = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell></TableCell>
      {Children.map(children, (child?: JSX.Element) => (
        <TableCell key={child?.props.label}>{child?.props.label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default SimpleDatagridHeader;
