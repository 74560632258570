const API_HOST = process.env.REACT_APP_API_HOST;

const config = Object.freeze({
  api: {
    baseUrl: API_HOST,
    graphQLEndpoint: `${API_HOST}/graphql`,
    webQraphQLEndpoint: `${API_HOST}/web-api-graphql`,
  },
});

export default config;
