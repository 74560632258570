import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query packageSequences($order: String, $offset: Int, $limit: Int, $where: JSON) {
    data: packageSequences(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      title
      description
      packageNumber
      packages {
        id
        uid
        title
        packageNumber
        publishedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
    total: packageSequences(where: $where) {
      id
    }
  }
`;
export const GET_MANY = GET_LIST;
