import { TextField, TextInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'ra-core';

import { BulkAssignSequenceDialog } from 'components/dialogs/BulkAssignSequenceDialog';
import { SelectableDatagrid, SelectableList } from 'components/SelectableList';
import { RECORDS_PER_PAGE } from 'constants/common';
import { Resources } from 'types';

import { Patient, patientFields } from './types';

const useStyles = makeStyles({
  overflow: {
    '& .column-providerId': { overflowWrap: 'anywhere' },
    '& .column-name': { overflowWrap: 'anywhere' },
  },
});

const exporter = (patients: Patient[]) => {
  const patientsForExport = patients.map(({ id, providerId, name, reviewsCount, momentsCount }: Patient) => ({
    [patientFields.id.admin]: id,
    [patientFields.patientsName.admin]: name,
    [patientFields.email.admin]: providerId,
    [patientFields.reviews.admin]: reviewsCount,
    [patientFields.moments.admin]: momentsCount,
  }));

  jsonExport(
    patientsForExport,
    {
      headers: Object.keys(patientsForExport[0]),
    },
    (err, csv) => {
      downloadCSV(csv, Resources.PATIENTS);
    },
  );
};

const patientFilters = [
  <TextInput label={patientFields.id.admin} source={patientFields.id.db} alwaysOn />,
  <TextInput label={patientFields.name.admin} source={patientFields.name.db} alwaysOn />,
];

export const PatientList = props => {
  const classes = useStyles();
  return (
    <SelectableList
      {...props}
      bulkActionButtons={<BulkAssignSequenceDialog resource={Resources.PATIENTS} />}
      filters={patientFilters}
      exporter={exporter}
      perPage={RECORDS_PER_PAGE}
    >
      <SelectableDatagrid rowClick="show" className={classes.overflow}>
        <TextField label={patientFields.id.admin} source={patientFields.id.db} />
        <TextField label={patientFields.name.admin} source={patientFields.name.db} />
        <TextField label={patientFields.email.admin} source={patientFields.email.db} />
        <TextField
          label={patientFields.currentPackageSequenceId.admin}
          source={patientFields.currentPackageSequenceId.db}
        />
        <TextField label={patientFields.reviews.admin} source={patientFields.reviews.db} />
        <TextField label={patientFields.moments.admin} source={patientFields.moments.db} />
      </SelectableDatagrid>
    </SelectableList>
  );
};
