export const clinicianFieldNames = {
  id: { db: 'id', admin: 'ID' },
  fullName: { db: 'profile.name', admin: 'Name' },
  firstName: { db: 'firstName', admin: 'First Name' },
  lastName: { db: 'lastName', admin: 'Last Name' },
  email: { db: 'deliveryAddress', admin: 'Email' },
  providerId: { db: 'providerId', admin: 'Email' },
  org: { db: 'providerId', admin: 'Org' },
  organization: { db: 'HCProviders[0].name', source: 'HCProviders[0].id', admin: 'Organization' },
  resetPassCode: { db: 'resetPasswordCode', admin: 'Reset Password Code' },
  resetPassExpiry: { db: 'resetPasswordCodeExpireAt', admin: 'Reset Password Code Expiry' },
};

export interface Clinician {
  id: number;
  providerId: string;
  HCProviders: {
    name: string;
  };
  profile: {
    name: string;
  };
}
