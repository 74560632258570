import {
  Edit,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useEditController,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PlainTextField from 'components/fields/PainTextField';
import { RegistrationLinkField } from 'components/fields/RegistrationLinkField';
import { Header } from 'components/Header';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { EditToolbar } from 'components/toolbars/EditToolbar';
import { useError } from 'hooks/useError';
import { isEmail } from 'utils/validation';

import { HCProvider, hcProviderFieldNames, PointOfContact } from './types';

const useStyles = makeStyles({
  subHeader: {
    borderBottom: '2px solid #9eadba',
    marginTop: '2%',
  },
  subGroup: {
    marginTop: '2%',
    marginBottom: '1%',
  },
});

/**
 * Transform PointOfContact data before request to the server
 *
 * @param data object representing PointOfContact record
 * @returns transformed PointOfContact data object
 */
const transformPOCData = ({ firstName, lastName, role, email, phone }: PointOfContact) => ({
  firstName,
  lastName,
  role,
  email,
  phone,
});

/**
 * Transform HCProvider data before request to the server
 *
 * @param data object representing HCProvider record
 * @returns transformed HCProvider data object
 */
const transformHCProviderData = ({ id, name, primaryPOC, secondaryPOC }: HCProvider) => ({
  id,
  name,
  primaryPOC: { ...transformPOCData(primaryPOC), id: primaryPOC.id },
  secondaryPOC: { ...transformPOCData(secondaryPOC), id: secondaryPOC.id },
});

export const HCProviderEdit = props => {
  const { resource, record } = useEditController(props);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const errorNotify = useError();
  const refresh = useRefresh();
  const classes = useStyles();

  /**
   * Callback that handles HCProvider creation
   *
   * @param record object representing HCProvider record
   * @returns void
   */
  const createHCProviderHandler = (record: HCProvider) => {
    const { name, primaryPOC, secondaryPOC } = record;
    dataProvider.create(
      resource,
      {
        data: {
          name,
          primaryPOC: transformPOCData(primaryPOC),
          secondaryPOC: transformPOCData(secondaryPOC),
        },
      },
      {
        onSuccess: () => {
          redirect(`${resource}`);
          notify('The new organization was created');
          refresh();
        },
        onFailure: error => errorNotify(error.message, 'warning'),
      },
    );
  };

  /**
   * Callback that handles successful HCProvider update
   *
   * @returns void
   */
  const successHandler = () => {
    redirect('list', `/${resource}`);
    notify('The organization was updated');
    refresh();
  };

  /**
   * Callback that handles failed HCProvider update
   *
   * @param error contains error message that was responded from the server
   * @returns void
   */
  const failureHandler = error => errorNotify(error.message, 'warning');

  return (
    <Edit
      {...props}
      transform={transformHCProviderData}
      actions={false}
      title={<Header resource="Orgs" id={record?.id} />}
      mutationMode="pessimistic"
      onSuccess={successHandler}
      onFailure={failureHandler}
    >
      <SimpleForm toolbar={<EditToolbar id={record?.id} onSaveAs={createHCProviderHandler} />}>
        <TextField label={hcProviderFieldNames.id.admin} source={hcProviderFieldNames.id.db} />
        <TextInput
          label={hcProviderFieldNames.name.admin}
          source={hcProviderFieldNames.name.db}
          validate={required()}
        />
        <RegistrationLinkField
          label={hcProviderFieldNames.registrationLink.admin}
          hashFieldName={hcProviderFieldNames.registrationLink.db}
        />
        <PlainTextField classes={{ root: classes.subHeader }} variant="h5">
          Points of Contact
        </PlainTextField>
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Primary
        </PlainTextField>
        <TextInput
          label={hcProviderFieldNames.primaryPOC.firstName.admin}
          source={hcProviderFieldNames.primaryPOC.firstName.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.lastName.admin}
          source={hcProviderFieldNames.primaryPOC.lastName.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.role.admin}
          source={hcProviderFieldNames.primaryPOC.role.db}
          validate={required()}
        />
        <TextInput
          label={hcProviderFieldNames.primaryPOC.email.admin}
          source={hcProviderFieldNames.primaryPOC.email.db}
          validate={[required(), isEmail(true)]}
        />
        <PhoneNumberInput
          label={hcProviderFieldNames.primaryPOC.phone.admin}
          source={hcProviderFieldNames.primaryPOC.phone.db}
          isRequired
        />
        <PlainTextField classes={{ root: classes.subGroup }} variant="subtitle1">
          Secondary
        </PlainTextField>
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.firstName.admin}
          source={hcProviderFieldNames.secondaryPOC.firstName.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.lastName.admin}
          source={hcProviderFieldNames.secondaryPOC.lastName.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.role.admin}
          source={hcProviderFieldNames.secondaryPOC.role.db}
        />
        <TextInput
          label={hcProviderFieldNames.secondaryPOC.email.admin}
          source={hcProviderFieldNames.secondaryPOC.email.db}
          validate={isEmail(false)}
        />
        <PhoneNumberInput
          label={hcProviderFieldNames.secondaryPOC.phone.admin}
          source={hcProviderFieldNames.secondaryPOC.phone.db}
        />
      </SimpleForm>
    </Edit>
  );
};
