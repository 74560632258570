import { BooleanField, Datagrid, downloadCSV, List, TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

import SimpleDatagridHeader from 'components/datagridHeaders/SimpleDatagridHeader';
import { RECORDS_PER_PAGE } from 'constants/common';
import { ResourceLabels, Resources } from 'types';

import { User, usersFieldNames } from './types';

const exporter = (users: User[]) => {
  const usersForExport = users.map(({ name, source, email, sendNews }) => ({
    [usersFieldNames.name.admin]: name,
    [usersFieldNames.source.admin]: source,
    [usersFieldNames.email.admin]: email,
    [usersFieldNames.sendNews.admin]: sendNews,
  }));
  jsonExport(
    usersForExport,
    {
      headers: Object.keys(usersForExport[0]),
    },
    (err, csv) => {
      downloadCSV(csv, ResourceLabels[Resources.MARKETING_WEBSITE_USERS]);
    },
  );
};

const useStyles = makeStyles({
  overflow: {
    '& .column-name': { overflowWrap: 'anywhere' },
    '& .column-source': { overflowWrap: 'anywhere' },
    '& .column-email': { overflowWrap: 'anywhere' },
  },
});

export const MarketingWebsitesUserList = props => {
  const classes = useStyles();
  return (
    <List {...props} exporter={exporter} perPage={RECORDS_PER_PAGE}>
      <Datagrid header={<SimpleDatagridHeader />} isRowSelectable={() => false} className={classes.overflow}>
        <TextField label={usersFieldNames.name.admin} source={usersFieldNames.name.db} />
        <TextField label={usersFieldNames.source.admin} source={usersFieldNames.source.db} />
        <TextField label={usersFieldNames.email.admin} source={usersFieldNames.email.db} />
        <BooleanField label={usersFieldNames.sendNews.admin} source={usersFieldNames.sendNews.db} />
      </Datagrid>
    </List>
  );
};
