import { FC } from 'react';
import { Resource, ResourceProps } from 'react-admin';

interface Props extends ResourceProps {
  contextProvider: FC;
}

export const ResourceWithContext = ({ contextProvider, ...props }: Props) => {
  const Context = contextProvider;

  return (
    <Context>
      <Resource {...props} />
    </Context>
  );
};
