import { FC, useContext } from 'react';
import { useMutation, useNotify, useRecordSelection, useRefresh, useResourceContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import { useError } from 'hooks/useError';
import { ResourcesContext } from 'types';

import BaseDeleteDialog from './BaseDeleteDialog';

export interface DeleteManyDialogProps {
  resourceLabels: {
    singular: string;
    plural: string;
  };
  title: string;
  disabled?: boolean;
}

const DeleteManyDialog: FC<DeleteManyDialogProps> = ({ resourceLabels, title, disabled }) => {
  const notify = useNotify();
  const errorNotify = useError();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const { isSelectedAll, excluded, setIsSelectedAll, setExcluded } = useContext(ResourcesContext[resource || '']);
  const [selectedIds, { clearSelection }] = useRecordSelection(resource);

  const labelProps =
    selectedIds.length === 1
      ? { currentLabel: resourceLabels.singular, labelTextualForm: `this ${resourceLabels.singular}` }
      : { currentLabel: resourceLabels.plural, labelTextualForm: `these ${resourceLabels.plural}` };

  const { currentLabel, labelTextualForm } = labelProps;

  const [deleteMany, { loading }] = useMutation(
    {
      type: 'deleteMany',
      resource,
      payload: { ids: isSelectedAll ? excluded : selectedIds, isExclude: isSelectedAll },
    },
    {
      onSuccess: () => {
        notify(`${currentLabel} deleted`, 'info');
        setIsSelectedAll(false);
        setExcluded([]);
        clearSelection();
        refresh();
      },
      onFailure: error => errorNotify(error.message, 'warning'),
    },
  );

  const basicDialogContent = <Typography>{`Are you sure you want to\ndelete ${labelTextualForm}?`}</Typography>;

  const extraWarningDialogContent = (
    <Typography color="secondary">
      {`By confirming this action you will permanently\ndelete ${labelTextualForm} from the system. Are you sure?`}
    </Typography>
  );

  return (
    <BaseDeleteDialog
      title={title}
      loading={loading}
      disabled={disabled}
      basicDialogContent={basicDialogContent}
      extraWarningDialogContent={extraWarningDialogContent}
      deleteHandler={deleteMany}
    />
  );
};

export default DeleteManyDialog;
