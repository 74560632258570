import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import CreateInviteToolbar from 'components/toolbars/CreateInviteToolbar';
import { PackageSequence } from 'resources/packageSequence/types';
import { Resources } from 'types';
import { renderOptionText } from 'utils';
import { buildPublishedAtFilter } from 'utils/filters';

import { patientFields } from './types';

import { hcProviderFieldNames } from '../hcProvider/types';

interface CreatePatientData {
  id: number;
  message: string;
}

export const PatientCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = ({ data }: { data: CreatePatientData }) => {
    notify(data.message);
    redirect(`/${Resources.PATIENTS}`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<CreateInviteToolbar />}>
        <TextInput label={patientFields.patientsName.admin} source={patientFields.patientsName.db} />
        <TextInput label={patientFields.deliveryAddress.admin} source={patientFields.deliveryAddress.db} />
        <ReferenceInput
          label={patientFields.clinician.admin}
          source={patientFields.clinician.db}
          reference={Resources.CLINICIANS}
        >
          <SelectInput optionText={patientFields.patientName.db} />
        </ReferenceInput>
        <ReferenceInput
          label={hcProviderFieldNames.reference.admin}
          source={hcProviderFieldNames.providerId.db}
          reference={Resources.HC_PROVIDERS}
        >
          <SelectInput optionText={hcProviderFieldNames.name.db} />
        </ReferenceInput>
        <ReferenceInput
          label={patientFields.sequence.admin}
          source={patientFields.sequence.db}
          reference={Resources.PACKAGE_SEQUENCES}
          filterToQuery={(searchText: string) => ({
            title: searchText,
          })}
          filter={buildPublishedAtFilter(true)}
          sort={{ field: 'id', order: 'ASC' }}
          defaultValue={1}
        >
          <AutocompleteInput optionText={({ uid, title }: PackageSequence) => renderOptionText(uid, title)} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
