import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

import { APP, Application } from './app';
import { GOOGLE_USER, GoogleUser } from './user';

interface Context {
  app: Application;
  user: GoogleUser;
  setApp: Dispatch<SetStateAction<Application>>;
  setUser: Dispatch<SetStateAction<GoogleUser>>;
}

const AppContext = createContext<Context>({
  app: APP,
  user: GOOGLE_USER,
  setApp: (() => void {}) as Context['setApp'],
  setUser: (() => void {}) as Context['setUser'],
});

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: ReactNode;
}

export const AppProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<GoogleUser>(GOOGLE_USER);
  const [app, setApp] = useState<Application>(APP);

  return <AppContext.Provider value={{ user, app, setUser, setApp }}>{children}</AppContext.Provider>;
};
