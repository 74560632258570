import { FC, useState } from 'react';

import { useQuery } from '@apollo/client';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { RECORDS_PER_PAGE } from 'constants/common';
import { client } from 'data/provider';
import { GET_ONBOARDING_SURVEY_ANSWERS } from 'data/queries/onboardingSurveyAnswers';
import {
  GET_ONBOARDING_SURVEY_SUBMISSIONS,
  GET_ONBOARDING_SURVEY_SUBMISSIONS_COUNT,
} from 'data/queries/onboardingSurveySubmissions';

import { Answer } from './types';

export const OnboardingSurveySubmissionList: FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RECORDS_PER_PAGE);
  const [order, setOrder] = useState('answerDate desc');

  const { data: total, loading: totalLoading } = useQuery(GET_ONBOARDING_SURVEY_SUBMISSIONS_COUNT, { client });
  const { data: answers } = useQuery(GET_ONBOARDING_SURVEY_ANSWERS, { client });
  const { data, loading } = useQuery(GET_ONBOARDING_SURVEY_SUBMISSIONS, {
    client,
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order,
    },
  });

  const patterns: GridColDef[] = [
    {
      field: 'answerDate',
      headerName: 'Date',
      disableColumnMenu: true,
    },
  ];

  if (data && answers) {
    const answerPatterns = answers.answers.map(
      (answer: Answer, index: number): GridColDef => ({
        field: `${index}`,
        headerName: answer.answer,
        valueGetter: params => params.row.answers.find(el => el.id === answer.id)?.count ?? 0,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      }),
    );

    patterns.push(...answerPatterns);
  }

  return (
    <div>
      <DataGrid
        rowHeight={32}
        rows={data?.data || []}
        columns={patterns}
        loading={loading}
        paginationMode="server"
        rowCount={totalLoading ? 0 : total.total}
        autoHeight
        pageSize={rowsPerPage}
        onPageSizeChange={value => setRowsPerPage(value)}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        onPageChange={value => setPage(value)}
        onSortModelChange={order => {
          setOrder(order[0].field + ' ' + order[0].sort);
        }}
      />
    </div>
  );
};
