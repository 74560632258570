import { createContext as createReactContext, FC, useState } from 'react';

import { LocalStorage, Sort } from 'types';

export interface ContextValues {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  currentSort: Sort;
  setCurrentSort: (sort: Sort) => void;
  isSelectedAll: boolean;
  setIsSelectedAll: any;
  excluded: number[];
  setExcluded: any;
}

type ContextAndProvider = {
  Context: React.Context<ContextValues>;
  ContextProvider: FC;
};

export const createContext = (entity: string): ContextAndProvider => {
  const Context = createReactContext<ContextValues>({} as ContextValues);

  const ContextProvider: FC = ({ children }) => {
    const listContext = localStorage.getItem(LocalStorage.LIST_CONTEXT);
    let currentPage: number = 1;
    let currentSort: Sort = { field: 'id', order: 'ASC' };

    if (listContext) {
      const parsedContext = JSON.parse(listContext);
      if (parsedContext[entity]) {
        currentPage = parsedContext[entity].currentPage;
        currentSort = parsedContext[entity].currentSort;
      }
    }
    const [page, setPage] = useState<number>(currentPage);
    const [sort, setSort] = useState<Sort>(currentSort);

    const setCurrentPage = (newPage: number) => {
      const stringified = localStorage.getItem(LocalStorage.LIST_CONTEXT);
      const oldListContext = stringified ? JSON.parse(stringified) : {};
      const newListContext = JSON.stringify({
        ...oldListContext,
        [entity]: { ...oldListContext[entity], currentPage: newPage },
      });
      localStorage.setItem(LocalStorage.LIST_CONTEXT, newListContext);
      setPage(newPage);
    };

    const setCurrentSort = (newSort: Sort) => {
      const stringified = localStorage.getItem(LocalStorage.LIST_CONTEXT);
      const oldListContext = stringified ? JSON.parse(stringified) : {};
      const newListContext = JSON.stringify({
        ...oldListContext,
        [entity]: { ...oldListContext[entity], currentSort: newSort },
      });
      localStorage.setItem(LocalStorage.LIST_CONTEXT, newListContext);
      setSort(newSort);
    };

    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [excluded, setExcluded] = useState([]);

    return (
      <Context.Provider
        value={{
          currentPage: page,
          setCurrentPage,
          currentSort: sort,
          setCurrentSort,
          isSelectedAll,
          setIsSelectedAll,
          excluded,
          setExcluded,
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  return { Context, ContextProvider };
};
