import * as Clinicians from './clinicians';
import * as HCProviders from './hcProviders';
import * as LoticUsers from './loticUsers';
import * as MarketingWebsitesUsers from './marketingWebsitesUsers';
import * as MomentSubmissions from './momentSubmissions';
import * as OnboardingSurveySubmissions from './onboardingSurveySubmissions';
import * as Organizations from './organizations';
import * as PackageSequences from './packageSequences';
import * as Patients from './patients';
import * as ThreadModerators from './threadModerator';
import * as ThreadModeratorInvites from './threadModeratorInvite';

const queries = {
  HCProviders,
  Clinicians,
  Patients,
  MomentSubmissions,
  Organizations,
  MarketingWebsitesUsers,
  PackageSequences,
  LoticUsers,
  OnboardingSurveySubmissions,
  ThreadModerators,
  ThreadModeratorInvites,
};

export default queries;
