// This enum is for getting input names for graphQL create/update mutations.
//This enum in separate file to avoid circular dependency error
export enum Entities {
  Clinicians = 'clinician',
  HCProviders = 'hcProvider',
  MomentSubmissions = 'momentSubmission',
  Patients = 'patient',
  PackageSequences = 'packageSequence',
  LoticUsers = 'loticUser',
  ThreadModerators = 'threadModerator',
}
