export interface Patient {
  id: number;
  providerId: string;
  name: string;
  reviewsCount: string;
  momentsCount: string;
}

export interface IPatientActivitiesTable {
  rows: any[];
  rowsPerPage: number;
}

export const patientFields = {
  id: { db: 'id', admin: 'ID' },
  patientName: { db: 'profile.name', admin: 'Name' },
  patientsName: { db: 'patientName', admin: 'Name' },
  name: { db: 'name', admin: 'Name' },
  currentPackageSequenceId: { db: 'currentPackageSequenceId', admin: 'Sequence ID' },
  email: { db: 'providerId', admin: 'Email' },
  deliveryAddress: { db: 'deliveryAddress', admin: 'Email Address' },
  reviews: { db: 'reviewsCount', admin: 'Reviews' },
  review: {
    id: { db: 'review.uid', admin: 'Review ID' },
    title: { db: 'review.title', admin: 'Title' },
    createdAt: { db: 'createdAt', admin: 'Date/Time' },
    questions: { db: 'reviewSubmissionAnswers', admin: 'Question ID' },
    answers: { db: 'reviewSubmissionAnswers', admin: 'Answer' },
  },
  reviewSubmission: {
    id: { db: 'review.uid', admin: 'Review ID' },
    title: { db: 'review.title', admin: 'Title' },
    createdAt: { db: 'createdAt', admin: 'Date/Time' },
    questions: { db: 'reviewSubmissionAnswers', admin: 'Question ID' },
    answers: { db: 'reviewSubmissionAnswers', admin: 'Answer' },
  },
  moments: { db: 'momentsCount', admin: 'Moments' },
  coins: { db: 'coins', admin: 'Coins' },
  coinTransactions: {
    admin: 'coinTransactions',
    createdAt: { db: 'createdAt', admin: 'Date/Time' },
    amount: { db: 'amount', admin: 'Coin Value' },
    activity: { db: 'activityType', admin: 'ID/Title' },
  },
  dailyStreak: { db: 'dailyStreak', admin: 'Daily Streak' },
  moment: {
    id: { db: 'moment.uid', admin: 'Moment ID' },
    createdAt: { db: 'createdAt', admin: 'Date/Time' },
    type: { db: 'type', admin: 'Type' },
    length: { db: 'durationMs', admin: 'Length' },
  },
  checkIns: { admin: 'Check-Ins' },
  checkInStatistics: {
    createdAt: { db: 'date', admin: 'Date/Time' },
    checkInIds: { db: 'checkInIds' },
    answers: { db: 'answers' },
  },
  checkInsPerDay: {
    mood: { admin: 'Mood' },
    sleep: { admin: 'Sleep' },
    energy: { admin: 'Energy' },
    stress: { admin: 'Stress' },
    pain: { admin: 'Pain' },
    appetite: { admin: 'Appetite' },
  },
  hcProvider: { admin: 'HC Provider' },
  clinician: { admin: 'Clinician', db: 'clinicianId' },
  lastLoginDate: { db: 'lastLoginDate', admin: 'Last Login Time' },
  momentSubmissions: { db: 'momentSubmissions' },
  reviewSubmissions: { db: 'reviewSubmissions' },
  sequence: { db: 'currentPackageSequenceId', admin: 'Sequence' },
  profile: {
    name: { db: 'profile.name' },
  },
  walletId: { db: 'walletId', admin: 'Wallet ID' },
};
