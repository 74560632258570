import { gql } from '@apollo/client';

export const COIN_TRANSACTIONS = gql`
  query coinTransactions($where: JSON, $limit: Int, $offset: Int, $order: String) {
    data: coinTransactions(where: $where, limit: $limit, offset: $offset, order: $order) {
      id
      amount
      activityType
      createdAt
    }
  }
`;

export const COIN_TRANSACTIONS_COUNT = gql`
  query coinTransactionsCount($where: JSON) {
    total: coinTransactionsCount(where: $where)
  }
`;
