import { FC, MouseEvent, useState } from 'react';
import { MenuItemLink } from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Fade, IconButton, Menu } from '@mui/material';

import { useAppContext } from 'context/appContext';
import { useLogout } from 'hooks/useGoogleAuth';

import './index.css';

export const UserMenu: FC = () => {
  const { user, setUser } = useAppContext();

  const { signOut } = useLogout({ user, setUser });

  const [anchorEl, setAnchorEl] = useState<null | EventTarget>(null);

  const clickHandler = (event: MouseEvent): void => setAnchorEl(event.currentTarget);
  const closeHandler = (): void => setAnchorEl(null);

  return (
    <div className="app-bar-container">
      <span className="app-bar_email">{user.email}</span>
      <IconButton onClick={clickHandler} color="inherit">
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{ 'aria-labelledby': 'fade-button' }}
        anchorEl={anchorEl as Element}
        open={Boolean(anchorEl)}
        onClose={closeHandler}
        TransitionComponent={Fade}
      >
        <MenuItemLink to="/LoticUsers" primaryText="Console Users" leftIcon={<SettingsIcon />} />
        <MenuItemLink onClick={signOut} to="/login" primaryText="Log out" leftIcon={<LogoutIcon />} />
      </Menu>
    </div>
  );
};
