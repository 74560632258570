import { ListButton, SaveButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles({
  saveAs: {
    marginLeft: '5px',
    padding: '5px 10px',
  },
  cancel: {
    marginLeft: 'auto',
  },
});

export const EditToolbar = ({ onSaveAs: saveAsHandler, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        variant="outlined"
        key={`${props.pristine}save`}
        disabled={props.pristine}
        submitOnEnter={true}
      />
      <ListButton
        label="Save As"
        variant="outlined"
        icon={<FileCopyIcon />}
        className={classes.saveAs}
        disabled={props.pristine}
        key={`${props.pristine}saveAs`}
        onClick={() => saveAsHandler(values)}
      />
      <ListButton label="Cancel" color="inherit" icon={<ClearIcon />} className={classes.cancel} />
    </Toolbar>
  );
};
